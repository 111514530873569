import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
// import Landing from "./assets/img/favicon.png";

export default function App() {
  document.title = 'PT. Pacific Multi Jaya'
  return (
    <>
      <Helmet>
        {/* <link rel="icon" href="./assets/img/favicon.ico" /> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Landing />
    </>
  );
}

