import React from "react";
import commstyles from "../../style/module/common.module.css";
import LogoIcon from "../../assets/svg/LogoFooter";
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {
  return (
    <footer className={`${commstyles['footer']}`}>
      <div className={`${commstyles['container']}`}>
        <div className={`${commstyles['row-footer']}`}>
          <div className={`${commstyles['footer-col']}`}>

            <LogoIcon className={`${commstyles['logo-footer']}`} />
            <p>
              PT. Pacific Multi Jaya leading the way in logistics and supply cloud seeding materials across Indonesia. Excellence, innovation, and reliability are at the core of our services
            </p>
          </div>
          <div className={`${commstyles['footer-col']}`}>
            <h4>Services</h4>
            <ul>
              <li><a href="#">Transporter</a></li>
              <li><a href="#">Supplier</a></li>
            </ul>
          </div>
          <div className={`${commstyles['footer-col']}`}>
            <h4>Menu</h4>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#about">About Us</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div className={`${commstyles['footer-col']}`}>
            <h4>Contact Us</h4>
            <div className={`${commstyles['txt-white']}`}>
              <div className={`${commstyles['row-footer']}`}>
                <div className={`${commstyles['footer-icon-container']}`}><FontAwesomeIcon icon={faEnvelope} size={'sm'} /></div>
                <div className={`${commstyles['footer-cu-spacer']}`} />
                <div>info@pmj-id.com</div>
              </div>
              <div className={`${commstyles['row-footer']} ${commstyles['no-wrap']}`}>
                <div className={`${commstyles['footer-icon-container']}`}><FontAwesomeIcon icon={faLocationDot} size={'sm'} /></div>
                <div className={`${commstyles['footer-cu-spacer']}`} />
                <div>Jl. Pantai Indah Selatan I, No.4, RT.4/RW.3, Kamal Muara, Kec. Penjaringan, Jakarta Utara, Daerah Khusus Ibukota Jakarta 14460</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${commstyles['copyright']} ${commstyles['row']}`}>
        <p>Copyright &copy; <a href="#home">PT. Pacific Multi Jaya</a></p>
      </div>
    </footer>
  );
}
