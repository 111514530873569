import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.12784in" height="0.914689in" viewBox="0 0 53.8 43.63" {...props} >
      <g id="Layer_x0020_1">
        <g id="_2816556066560">
          <g>
            <path fill="#42729F" d="M19.78 21.31l-0 1.14 0.01 2.73 0 1.14 -0.01 1.78c-0.67,0 -1.39,0.11 -1.82,-0.3 -0.27,-0.27 -0.53,-0.95 -0.6,-1.4l-0.46 -1.22c-0.39,-0.47 -0.7,-0.52 -1.47,-0.52l-4.48 -0c-0.57,-0 -1.04,-0.08 -1.16,0.42 -0.06,0.24 0.19,0.84 0.28,1.14 0.27,0.98 0.91,2.63 1.1,3.54 0.46,0.11 1.22,0.71 2.35,-0.12 2.47,-1.81 2.47,0.62 4.44,0.41 1.01,-0.11 1.17,-0.89 2.11,-1.03 1.18,-0.17 1.49,0.48 2.18,0.84 1.92,0.99 2.69,-1.82 4.6,-0.57 0.6,0.4 1.16,1.06 2.3,0.66 0.78,-0.27 0.98,-0.98 2.1,-0.96 1.08,0.02 1.27,0.73 2.08,0.98 1.99,0.61 2.42,-2.14 4.59,-0.48 0.6,0.46 1.37,0.85 2.3,0.34 0.66,-0.36 1.03,-1.02 2.19,-0.8 0.9,0.17 1.06,0.91 2.05,1.02 1.28,0.14 1.44,-0.62 2.24,-0.9l0.12 -0.25c0.24,-0.72 1.08,-3.15 0.99,-3.61 -0.13,-0.65 -0.77,-0.61 -1.11,-0.62l-2.57 0 -8.1 0c-1.3,0 -1.25,1.88 -1.97,2.96 -0.25,0.37 -0.59,0.48 -1.17,0.48l-4.37 -0 0.02 -1.77 -0 -1.14 0 -2.74 -0.03 0 -0.01 2.73 -0 1.14 0 1.77 -4.35 0 -4.37 0 0.04 -1.77 -0 -1.14 -0.02 -2.73 -0.01 -1.14 -0.01 -2.75 -0.02 -0.02 0.01 2.76z" />
            <path fill="#0FD8FF" d="M46.04 31.46c-0.35,0.56 -1.38,0.79 -2.17,0.39 -0.71,-0.36 -0.93,-1.01 -2.15,-0.84 -0.9,0.12 -1.09,0.83 -1.99,1.01 -1.08,0.21 -1.5,-0.38 -2.08,-0.74 -0.82,-0.51 -1.62,-0.33 -2.28,0.18 -2.22,1.71 -2.54,-0.61 -4.33,-0.46 -0.97,0.08 -1.17,0.75 -1.95,0.98 -1.91,0.55 -2.47,-2.03 -4.45,-0.58 -2.39,1.74 -2.52,-0.4 -4.19,-0.41 -1.77,-0.01 -1.77,2.16 -4.21,0.43 -2.09,-1.48 -2.61,1.12 -4.38,0.56 -0.71,-0.13 -1.28,-1.05 -2.04,-1.01 -0.11,0.01 -0.17,0.06 -0.19,0.16l0 1.16c0,0.52 0,1.05 0,1.57 0.42,0.2 0.55,-0.01 1.22,0.5 2.74,2.1 2.99,-1.84 5.51,0.09 0.18,0.14 0.32,0.25 0.53,0.36 1.73,0.89 2.26,-1.22 3.98,-0.85 0.99,0.21 1.76,1.92 3.65,0.46 1.79,-1.37 2.61,0.37 3.63,0.54 1.37,0.23 1.64,-0.7 2.51,-0.97 0.48,-0.15 1.08,-0.07 1.46,0.14 0.46,0.25 1.28,1.26 2.54,0.72 0.48,-0.21 0.62,-0.44 1.03,-0.68 1.72,-1 2.29,1 3.94,0.8 1.03,-0.13 1.22,-1.05 2.4,-1.05 1.32,0 1.4,0.96 2.47,1.05 1.46,0.12 1.51,-0.78 2.45,-0.99 0.62,-0.14 0.54,0.16 0.54,-1.18 -0,-0.6 0,-1.19 0,-1.79 -0.72,-0.17 -1.05,0.35 -1.43,0.45z" />
            <path fill="#355D7B" d="M11.18 29.76c0.08,0.16 0.65,1.99 0.67,2.22 1.77,0.56 2.28,-2.05 4.38,-0.56 2.44,1.73 2.44,-0.44 4.21,-0.43 1.67,0.01 1.8,2.15 4.19,0.41 1.99,-1.45 2.55,1.12 4.45,0.58 0.79,-0.23 0.99,-0.9 1.95,-0.98 1.79,-0.14 2.1,2.18 4.33,0.46 0.66,-0.51 1.46,-0.69 2.28,-0.18 0.58,0.36 1,0.95 2.08,0.74 0.9,-0.18 1.09,-0.89 1.99,-1.01 1.22,-0.17 1.45,0.49 2.15,0.84 0.79,0.4 1.83,0.18 2.17,-0.39 0.04,-0.34 0.24,-0.81 0.35,-1.15 0.1,-0.3 0.3,-0.86 0.33,-1.17 -0.8,0.29 -0.96,1.04 -2.24,0.9 -0.99,-0.11 -1.15,-0.85 -2.05,-1.02 -1.17,-0.22 -1.53,0.44 -2.19,0.8 -0.93,0.51 -1.7,0.12 -2.3,-0.34 -2.17,-1.66 -2.6,1.09 -4.59,0.48 -0.81,-0.25 -1,-0.96 -2.08,-0.98 -1.12,-0.02 -1.32,0.69 -2.1,0.96 -1.14,0.4 -1.7,-0.27 -2.3,-0.66 -1.91,-1.25 -2.68,1.56 -4.6,0.57 -0.69,-0.36 -1,-1.01 -2.18,-0.84 -0.95,0.14 -1.1,0.92 -2.11,1.03 -1.97,0.21 -1.97,-2.22 -4.44,-0.41 -1.14,0.83 -1.89,0.23 -2.35,0.12z" />
            <path fill="#E5F4FF" d="M41.15 18.91c0.94,-0.33 0.94,1.71 0.34,1.96 -0.58,0.24 -0.78,-0.26 -0.79,-0.75 -0.01,-0.66 -0.09,-1.01 0.45,-1.2zm-1.88 -0.01c0.5,-0.15 0.69,0.29 0.69,0.76 0.01,0.55 0.08,1.11 -0.39,1.23 -0.59,0.16 -0.75,-0.29 -0.76,-0.78 -0.01,-0.65 -0.1,-1.05 0.46,-1.21zm-1.97 3.17l6.03 0.04 0.25 -0.14c0,-0.32 0.08,-0.85 -0.06,-1.1 -0.85,0.1 -0.89,-0.17 -0.89,-0.99 0,-0.7 0.1,-1.14 0.85,-0.95 0.07,-0.1 0.03,-0.01 0.07,-0.18l0.02 -0.43c-0,-0.21 -0,-0.41 -0,-0.62l-0.16 -0 -6.12 0.01 0 4.37z" />
            <path fill="#FF6868" d="M36.04 24.67l8.1 -0c0,-0.45 0.09,-1.8 -0.12,-2.16l-0.26 -0.28c-0.07,-0.07 -0.07,-0.07 -0.12,-0.14l-0.3 0.02 -6.03 -0.04c-0.66,-0.09 -1.16,0.18 -1.25,0.66 -0.07,0.33 -0.01,1.51 -0.01,1.93z" />
            <path fill="#6193FE" d="M19.76 17.46l4.4 -0.02c0,-1.04 0.13,-2.57 -0.07,-3.45 -0.54,-0.13 -3.18,-0.16 -3.88,-0.07 -0.63,0.08 -0.45,0.78 -0.45,1.49 0,0.68 0,1.36 0,2.05z" />
            <polygon fill="#FFDA06" points="24.17,18.58 24.17,21.31 28.57,21.33 28.57,18.25 28.33,18.04 24.17,18.02 " />
            <path fill="#FFDA06" d="M15.43 21.31l4.35 0 -0.01 -2.76 -0 -0.52c-0.79,0 -3.81,-0.14 -4.27,0.07 -0.19,0.39 -0.07,2.56 -0.07,3.21z" />
            <path fill="#6193FE" d="M28.55 22.44l-0 2.74 4.35 0 0.01 -2.84c-0.02,-0.4 -0.14,-0.43 -0.53,-0.45 -1.24,-0.04 -2.58,0 -3.82,0l-0.01 0.54z" />
            <path fill="#C4D9EB" d="M40.79 15.63c0.25,0.13 0.9,0.02 1.22,0.02 -0.2,-0.58 -0.07,-1.4 -0.07,-2.04 0.42,0 0.86,0.01 1.28,0.01 0.43,-0.01 0.68,-0.16 0.67,-0.6 -0.02,-0.81 -1.15,-0.6 -1.95,-0.6l0 -0.67c0.61,0 1.13,0.07 1.12,-0.6 -0,-0.25 -0.15,-0.41 -0.32,-0.5 -0.24,-0.13 -0.47,-0.09 -0.8,-0.09 0,-0.36 0.12,-1.07 -0.59,-1.05 -0.56,0.01 -0.6,0.48 -0.6,1.05 -1.67,0 -0.98,1.04 -0.83,1.12 0.18,0.1 0.54,0.07 0.83,0.07l0 0.67c-0.83,0 -1.9,-0.17 -1.92,0.61 -0,0.11 0.08,0.33 0.15,0.41 0.26,0.3 1.17,0.18 1.77,0.18 0,0.6 -0.09,1.47 0.05,2.02z" />
            <polygon fill="#6193FE" points="19.81,21.31 24.17,21.31 24.17,18.58 19.8,18.56 " />
            <polygon fill="#FFDA06" points="24.17,25.18 28.52,25.18 28.53,22.45 24.17,22.46 " />
            <polygon fill="#6193FE" points="19.84,25.18 24.17,25.18 24.17,22.46 19.82,22.45 " />
            <path fill="#FF6868" d="M37.31 17.71l6.12 -0.01c-0.01,-0.02 -0.03,-0.07 -0.03,-0.06l-0.09 -0.24c-0.04,-0.14 -0.04,-0.21 -0.09,-0.37 -0.21,-0.63 -0.72,-1.11 -1.38,-1.28 0.35,0.05 0.02,0.02 0.17,-0.11 -0.32,0 -0.97,0.11 -1.22,-0.02 -0.95,0 -2.18,-0.1 -2.81,0.23 -0.62,0.32 -1.12,0.91 -1.12,1.87l0.45 -0.01z" />
            <path fill="#FFDA06" d="M15.43 24.67c0.78,0 1.09,0.05 1.47,0.52l2.89 -0.01 -0.01 -2.73 -4.35 0 0 2.21z" />
            <polygon fill="#6193FE" points="32.9,28.1 32.9,26.33 28.55,26.33 28.54,28.1 " />
            <polygon fill="#6193FE" points="24.17,28.1 24.17,26.33 19.84,26.33 19.8,28.1 " />
            <polygon fill="#FFDA06" points="24.17,28.1 28.52,28.1 28.52,26.33 24.17,26.33 " />
            <path fill="#C4D9EB" d="M43.49 18.94c0.33,0.26 0.3,0.39 0.3,0.95 0,0.7 0.04,0.5 -0.26,0.99 0.14,0.24 0.06,0.78 0.06,1.1l-0.25 0.14 0.3 -0.02c0.42,0.01 1.41,0.1 1.71,-0.04l0.02 -4.35 -1.79 0c0,0.21 0,0.41 0,0.62l-0.02 0.43c-0.04,0.17 -0,0.08 -0.07,0.18z" />
            <path fill="#FF4848" d="M43.64 22.1c0.05,0.07 0.05,0.06 0.12,0.14l0.26 0.28c0.21,0.35 0.12,1.71 0.12,2.16l2.57 -0c0,-0.44 0.05,-1.56 -0.03,-1.91 -0.06,-0.28 -0.22,-0.43 -0.42,-0.55 -0.28,-0.18 -0.47,-0.15 -0.92,-0.15 -0.3,0.15 -1.29,0.05 -1.71,0.04z" />
            <path fill="#FF4848" d="M43.43 17.71l0.16 0 1.79 -0 0.52 0.02c0,-0.9 -0.54,-1.56 -1.12,-1.87 -0.5,-0.26 -1.98,-0.34 -2.76,-0.21 -0.15,0.13 0.18,0.16 -0.17,0.11 0.66,0.17 1.17,0.65 1.38,1.28 0.05,0.15 0.05,0.22 0.09,0.37l0.09 0.24c0,-0.01 0.02,0.04 0.03,0.06z" />
            <polygon fill="#407BFF" points="19.82,22.45 24.17,22.46 24.17,21.31 19.81,21.31 " />
            <polygon fill="#407BFF" points="28.55,26.33 32.9,26.33 32.9,25.18 28.55,25.18 " />
            <polygon fill="#FFAB1E" points="24.17,26.33 28.52,26.33 28.52,25.18 24.17,25.18 " />
            <polygon fill="#FFAB1E" points="15.43,22.45 19.78,22.45 19.78,21.31 15.43,21.31 " />
            <polygon fill="#FFAB1E" points="24.17,22.46 28.53,22.45 28.55,22.44 28.56,21.9 28.57,21.33 24.17,21.31 " />
            <polygon fill="#407BFF" points="19.84,26.33 24.17,26.33 24.17,25.18 19.84,25.18 " />
            <polygon fill="#407BFF" points="19.77,18.02 19.78,18.55 19.8,18.56 24.17,18.58 24.17,18.02 24.17,17.44 19.76,17.46 " />
            <path fill="#FFDA06" d="M19.79 26.33c-0.33,0 -2.15,-0.09 -2.43,0.08 0.08,0.45 0.33,1.13 0.6,1.4 0.42,0.41 1.15,0.3 1.82,0.3l0.01 -1.78z" />
            <path fill="#FFAB1E" d="M17.36 26.4c0.28,-0.17 2.1,-0.08 2.43,-0.08l-0 -1.14 -2.89 0.01 0.46 1.22z" />
            <path fill="#10D9FF" d="M39.28 18.91c-0.55,0.16 -0.46,0.56 -0.46,1.21 0.01,0.49 0.17,0.94 0.76,0.78 0.47,-0.13 0.4,-0.69 0.39,-1.23 -0.01,-0.47 -0.19,-0.9 -0.69,-0.76z" />
            <path fill="#0FD8FF" d="M41.15 18.91c-0.53,0.19 -0.46,0.55 -0.45,1.2 0.01,0.49 0.21,1 0.79,0.75 0.6,-0.25 0.59,-2.29 -0.34,-1.96z" />
            <path fill="#10D9FF" d="M43.53 20.88c0.3,-0.5 0.26,-0.29 0.26,-0.99 -0,-0.56 0.04,-0.69 -0.3,-0.95 -0.76,-0.19 -0.85,0.26 -0.85,0.95 0,0.82 0.04,1.09 0.89,0.99z" />
          </g>
          <g>
            <g>
              <path fill="#61AFF6" d="M39.25 37.65l-0 0.01c0.07,0.03 0.96,0.01 1.12,0.01 1.86,0 3.73,-0.01 5.58,-0 0.09,0 0.18,0.01 0.27,0.01 0.63,-0 1.35,0.01 1.95,-0l0 1.06 0.99 -0 0.02 -0.01c0,-0.08 -0.02,-0.17 -0.01,-0.27 0.01,-0.08 0,-0.18 0.02,-0.26l0.06 -0.22c0.03,-0.09 0.13,-0.29 0.18,-0.37 0.05,-0.07 0.2,-0.25 0.26,-0.3 0.05,-0.05 0.09,-0.08 0.15,-0.13 0.05,-0.04 0.12,-0.08 0.17,-0.11 0.24,-0.12 0.12,-0.08 0.4,-0.16 0.04,-0.01 0.06,-0.03 0.11,-0.03 0.1,-0.01 0.32,-0.04 0.38,-0.03 0.09,0.01 0.16,0.02 0.25,0.02l0.32 0.1c0.04,0.01 0.06,0.03 0.1,0.04l0.06 0.02 -0 -1.89c-0.29,-0.01 -0.61,-0 -0.9,-0 -0.3,0 -0.6,0 -0.89,0 -0.14,0 -0.3,0.02 -0.42,-0.02 -0.14,-0.04 -0.17,-0.15 -0.17,-0.28l0 -2.23c-0,-0.17 0,-0.25 0.12,-0.33 0.1,-0.06 0.69,-0.04 0.86,-0.04 0.15,0 0.3,0 0.45,-0 0.11,-0 0.34,-0.01 0.44,0 -0.11,-0.01 -0.24,-0 -0.35,-0 -0.15,0 -0.13,0.02 -0.26,-0.12 -0.11,-0.12 -0.23,-0.24 -0.36,-0.37 -0.15,-0.15 -0.53,-0.59 -0.72,-0.64 -0.04,-0.01 -0.05,0 -0.05,-0.04 -0.2,0 -0.39,0 -0.59,0 -0.12,0 -0.51,0.01 -0.59,-0.01 -0.02,0.03 -0.01,5.36 -0.01,6.43 -0,0.09 0.01,0.12 -0.03,0.17 -0.12,0.01 -1.67,0 -1.96,0 -0.09,0 -0.18,-0.01 -0.26,-0.01 -0.74,0.01 -1.49,0 -2.23,0l-3.35 0c-0.25,0 -0.9,0.01 -1.11,-0z" />
              <path fill="#3999EA" d="M34.81 38.74l18.5 0c0.27,0 0.49,-0.22 0.49,-0.49l0 -4.84c0,-0.14 -0.08,-0.25 -0.23,-0.4 -0.44,-0.45 -0.49,-0.52 -0.83,-0.85 -0.22,-0.22 -0.52,-0.53 -0.73,-0.73 -0.19,-0.19 -0.25,-0.35 -0.85,-0.36l-2.97 0 0 6.6 -2.12 0 0 0.01 -11.68 0 0 0.64c0,0.23 0.19,0.42 0.42,0.42z" />
              <path fill="#61AFF6" d="M48.19 38.74l2.96 0c0.27,0 0.49,-0.22 0.49,-0.49l0 -4.84c0,-0.14 -0.08,-0.25 -0.23,-0.4 -0.44,-0.45 -0.49,-0.52 -0.83,-0.85 -0.22,-0.22 -0.52,-0.53 -0.73,-0.73 -0.19,-0.19 -0.25,-0.35 -0.85,-0.36l-0.81 0 0 7.67z" />
              <g>
                <circle fill="#3D6586" cx="50.85" cy="38.55" r="1.7" />
                <circle fill="#CDCBC9" cx="50.85" cy="38.55" r="0.66" />
              </g>
              <path fill="#32D8DD" d="M51.63 35.13c0.23,-0.01 0.48,-0 0.71,0 0.12,0 0.21,0 0.28,-0.07 0.06,-0.05 0.1,-0.14 0.1,-0.25 -0,-0.23 -0,-0.47 -0,-0.7 0,-0.33 -0,-0.34 -0.18,-0.52 -0.22,-0.22 -0.48,-0.48 -0.7,-0.7 -0.12,-0.12 -0.23,-0.23 -0.35,-0.35 -0.13,-0.13 -0.25,-0.29 -0.39,-0.31 -0.1,-0.02 -0.33,-0 -0.44,-0 -0.15,0 -0.3,0 -0.45,0 -0.17,0 -0.76,-0.03 -0.86,0.04 -0.12,0.07 -0.12,0.15 -0.12,0.33l-0 2.23c0,0.13 0.03,0.24 0.17,0.28 0.12,0.04 0.28,0.02 0.42,0.02 0.3,0 0.59,0 0.89,0 0.3,0 0.61,-0.01 0.9,0z" />
              <path fill="#EDA458" d="M45.99 37.67l2.2 0 0 -9.62c0,-0.21 -0.17,-0.39 -0.39,-0.39l-1.81 0 0 10.01z" />
              <path fill="#FFC06C" d="M46.08 37.67l-11.68 0 0 -9.63c0,-0.21 0.17,-0.39 0.39,-0.39l11.3 0 0 10.02z" />
              <path fill="#FF4C4C" d="M42.82 27.66l-3.23 0 -0 3.23c-0,0.24 0.06,0.34 0.31,0.34l2.59 -0c0.13,0 0.17,0.01 0.26,-0.06 0.07,-0.07 0.08,-0.13 0.07,-0.26 -0,-0.22 -0,-0.43 -0,-0.65 0,-0.87 0.01,-1.74 -0,-2.6z" />
              <rect fill="#FEFEFE" x="43.28" y="34.09" width="3.63" height="0.58" rx="1.68" ry="0.25" />
              <rect fill="#FEFEFE" x="43.28" y="35.78" width="3.63" height="0.58" rx="1.68" ry="0.25" />
              <g>
                <circle fill="#3D6586" cx="37.79" cy="38.55" r="1.7" />
                <circle fill="#CDCBC9" cx="37.79" cy="38.55" r="0.66" />
              </g>
            </g>
            <g>
              <path fill="#BE7D5A" d="M33.85 43.35c-0.1,-0.15 -0.06,-0.21 -0.06,-0.59 -0,-0.47 -0.09,-1.45 0.06,-1.69 -0.27,-0.04 -1.59,-0.86 -1.9,-1.04 -0.32,-0.18 -0.6,-0.35 -0.91,-0.53 -0.29,-0.16 -0.59,-0.37 -0.9,-0.52l0 2.29 3.46 2c0.09,0.06 0.13,0.1 0.25,0.08z" />
              <path fill="#FDBB1E" d="M41.72 41.04l-0.33 -0.13 -0.33 0.49 0.01 -0.8 -0.84 0.46c-0.07,0.22 -0.03,2.18 -0.01,2.57 0.12,-0.04 0.16,-0.08 0.27,-0.14 0.33,-0.19 1.99,-1.17 2.15,-1.21l0 -1.96c0,-0.15 0.03,-0.5 -0.04,-0.61l-0.81 0.46c0.01,0.19 0.04,0.61 -0.01,0.79l-0.03 0.07z" />
              <path fill="#F9A83C" d="M40.21 43.63c-0.02,-0.39 -0.05,-2.35 0.01,-2.57 -0.2,-0.03 -1.85,-1.02 -2.13,-1.2l0 2.59c0.17,0.04 0.85,0.46 1.04,0.58l1.08 0.6z" />
              <path fill="#AA6256" d="M33.96 43.06l0 -0.52 0.88 -0.52c0.01,0.67 0.07,0.51 -0.41,0.79 -0.13,0.07 -0.34,0.22 -0.47,0.25zm1.19 -2.78l-0.01 0.75 -0.29 -0.1 -0.29 0.43 -0 -0.69 -0.71 0.4c-0.15,0.23 -0.06,1.22 -0.06,1.69 0,0.39 -0.04,0.44 0.06,0.59l2.1 -1.21c0,-0.42 0.07,-2.06 -0.03,-2.31l-0.77 0.45z" />
              <path fill="#DAA560" d="M30.14 38.99c0.31,0.14 0.61,0.35 0.9,0.52 0.32,0.18 0.59,0.34 0.91,0.53 0.31,0.18 1.63,1 1.9,1.04l0.71 -0.4c-0.16,-0.17 -1.51,-0.91 -1.82,-1.08 -0.32,-0.18 -0.59,-0.35 -0.91,-0.53 -0.19,-0.11 -0.77,-0.42 -0.89,-0.54 -0.15,0.03 -0.29,0.13 -0.42,0.21 -0.15,0.09 -0.27,0.13 -0.38,0.25z" />
              <path fill="#DAA560" d="M31.54 38.18l3.61 2.11 0.77 -0.45 -0.89 -0.52c-0.18,-0.11 -2.65,-1.58 -2.75,-1.57l-0.72 0.42c-0,0 -0.02,0.01 -0.02,0.02z" />
              <path fill="#EEEDFC" d="M30.94 38.52c0.12,0.12 0.7,0.43 0.89,0.54 0.32,0.18 0.59,0.35 0.91,0.53 0.3,0.18 1.66,0.92 1.82,1.08l0 0.69 0.29 -0.43 0.29 0.1 0.01 -0.75 -3.61 -2.11c-0.15,0.03 -0.19,0.09 -0.32,0.16 -0.12,0.06 -0.18,0.08 -0.27,0.19z" />
              <path fill="#FFD06C" d="M39.57 38.99c0.19,0.02 0.9,0.46 1.09,0.58 0.27,0.17 0.79,0.49 1.05,0.6l0.01 0.87 0.03 -0.07c0.05,-0.18 0.03,-0.61 0.01,-0.79l0.81 -0.46c-0.18,-0.16 -0.52,-0.33 -0.73,-0.45 -0.14,-0.08 -0.23,-0.13 -0.36,-0.21l-0.74 -0.44c-0.32,-0.2 -0.28,-0.13 -0.8,0.16 -0.12,0.07 -0.28,0.15 -0.38,0.22z" />
              <path fill="#FFD06C" d="M38.09 39.86c0.29,0.18 1.94,1.17 2.13,1.2l0.84 -0.46 -0.13 -0.09c-0.36,-0.22 -1.86,-1.05 -1.95,-1.16 -0.18,0.07 -0.31,0.15 -0.47,0.25 -0.15,0.09 -0.29,0.17 -0.43,0.25z" />
              <path fill="#EDECFA" d="M38.99 39.35c0.09,0.11 1.59,0.94 1.95,1.16l0.13 0.09 -0.01 0.8 0.33 -0.49 0.33 0.13 -0.01 -0.87c-0.27,-0.11 -0.78,-0.43 -1.05,-0.6 -0.19,-0.12 -0.9,-0.56 -1.09,-0.58 -0.17,0.12 -0.47,0.23 -0.58,0.36z" />
              <path fill="#7A8497" d="M33.96 43.06c0.13,-0.03 0.34,-0.18 0.47,-0.25 0.48,-0.28 0.42,-0.12 0.41,-0.79l-0.88 0.52 -0 0.52z" />
            </g>
          </g>
          <g>
            <path fill="#84B34C" fillRule="nonzero" d="M30.23 7.75l0.23 1.15c0.1,0.51 0.55,0.88 1.08,0.88l2.38 0 0 -2.03 -3.69 0z" />
            <path fill="#9AD9E5" fillRule="nonzero" d="M12.62 9.58c-0.29,0 -0.54,0.08 -0.68,0.2 -0.14,-0.12 -0.39,-0.2 -0.68,-0.2 -0.43,0 -0.78,0.18 -0.78,0.39l1.36 0 0.21 0 1.36 0c0,-0.22 -0.35,-0.39 -0.78,-0.39z" />
            <polygon fill="#5C7842" fillRule="nonzero" points="21.3,12.17 20.46,12.17 20.88,11.15 21.3,10.14 21.72,11.15 22.14,12.17 " />
            <polygon fill="#4C4C49" fillRule="nonzero" points="37.95,7.45 38.04,8.13 36.34,8.37 35.52,7.79 " />
            <path fill="#F05667" fillRule="nonzero" d="M36.06 7.39l-0.07 1.09 -1.01 0.2 0.07 -1.11c0.33,-0.06 0.66,-0.12 1.01,-0.19z" />
            <path fill="#EF6F5A" fillRule="nonzero" d="M4.85 0.59l1.14 2.48 0.11 0.24 1.02 2.21 0.11 0.24 0.78 1.69 0.11 0.24 0.27 0.58 7.29 -1.03c0,0 -0.36,-0.01 -1.18,-0.45 -0.11,-0.06 -0.23,-0.13 -0.36,-0.2 -0.21,-0.13 -0.45,-0.28 -0.71,-0.46 -1.31,-0.91 -3.25,-2.55 -5.96,-5.54 -0.03,-0.03 -0.06,-0.07 -0.1,-0.11 -0.32,-0.35 -0.79,-0.52 -1.26,-0.46l-1.04 0.15c-0.04,0.01 -0.08,0.02 -0.12,0.04 -0.12,0.08 -0.18,0.24 -0.11,0.39z" />
            <path fill="#9B4E42" fillRule="nonzero" d="M8.01 7.44l0.11 0.24 6.38 -0.9c-0.11,-0.06 -0.23,-0.13 -0.36,-0.2l-6.14 0.87z" />
            <path fill="#9B4E42" fillRule="nonzero" d="M4.96 0.2l1.53 2.79 0.13 0.23 1.2 2.18 0.13 0.23 0.92 1.67 0.13 0.23 0.32 0.58 0.26 -0.04 -0.32 -0.58 -0.13 -0.23 -3.92 -7.14 -0.13 0.02c-0.04,0.01 -0.08,0.02 -0.12,0.04z" />
            <polygon fill="#9B4E42" fillRule="nonzero" points="7.12,5.51 7.23,5.75 8.04,5.63 8,5.39 " />
            <polygon fill="#9B4E42" fillRule="nonzero" points="5.99,3.07 6.1,3.31 6.74,3.22 6.7,2.97 " />
            <path fill="#9B4E42" fillRule="nonzero" d="M6.39 0.73l1.97 3.06c1.09,1.7 3.07,2.6 5.07,2.32 -1.31,-0.91 -3.25,-2.55 -5.96,-5.54l-1.09 0.15z" />
            <path fill="#FEFEFE" fillRule="nonzero" stroke="#D4D4D4" strokeWidth="0.14" strokeMiterlimit="10" d="M6.62 9.18c0.03,0.22 0.17,0.42 0.42,0.52 1.66,0.6 3.35,1.09 5.07,1.45 0.09,0.02 0.17,0.04 0.26,0.05 2.74,0.56 5.53,0.82 8.32,0.77 0.7,-0.01 1.4,-0.04 2.1,-0.09 0.48,-0.04 0.96,-0.08 1.45,-0.14 0.28,-0.03 0.56,-0.07 0.84,-0.1 1.83,-0.25 3.65,-0.5 5.43,-0.75 7.75,-1.09 7.55,-1.21 9.73,-2.28 3.61,-1.77 0.59,-2.75 0.59,-2.75 -0.29,-0.3 -0.65,-0.53 -1.04,-0.72 -1.92,-0.9 -4.88,-0.62 -7.45,-0.26l-25.17 3.55c-0.41,0.06 -0.6,0.42 -0.56,0.75z" />
            <path fill="#4C4245" fillRule="nonzero" d="M31.74 6.59c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M30.7 6.74c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.34 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M29.67 6.88c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M27.37 7.21c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M26.34 7.35c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M25.3 7.5c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.34 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M23.01 7.82c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M21.98 7.97c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M20.94 8.11c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M18.65 8.44c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M17.62 8.58c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#4C4245" fillRule="nonzero" d="M16.58 8.73c0.17,-0.02 0.28,0.1 0.25,0.27l-0.05 0.33c-0.03,0.17 -0.18,0.33 -0.35,0.36 -0.17,0.02 -0.28,-0.1 -0.25,-0.27l0.05 -0.33c0.03,-0.17 0.18,-0.33 0.35,-0.36z" />
            <path fill="#FEFEFE" fillRule="nonzero" d="M10.12 9.59c-0.91,0 -1.81,-0.26 -2.59,-0.75 0,0 1.67,-0.24 4.18,-0.59l0.16 1.14 -1.08 0.15c-0.22,0.03 -0.45,0.05 -0.67,0.05zm2 -0.23l-0.16 -1.14c0.26,-0.04 0.53,-0.07 0.8,-0.11l0.8 -0 1.6 -0c-0.08,-0.11 -0.17,-0.21 -0.26,-0.3 6.11,-0.86 14.28,-2.02 17.39,-2.45 1.53,-0.22 2.77,-0.29 3.77,-0.29 2.42,0 3.38,0.45 3.38,0.45l-12.07 1.7c0,0 -0,0 -0,0 -0,0 -0,0 -0,0l-4.36 0.61c-0,0 -0,0 -0,0 -0,0 -0,0 -0,0l-1.03 0.15c-0,0 -0,0 -0,0 -0,0 -0,0 -0,0l-3.32 0.47c-0,0 -0,0 -0,0 -0,0 -0,0 -0,0l-6.53 0.92z" />
            <path fill="#5C5457" fillRule="nonzero" d="M27.37 7.21c0,0 0,0 0,0 0,0 0,0 0,0l-0 0z" />
            <path fill="#5C5457" fillRule="nonzero" d="M21.98 7.97c0,0 0,-0 0,-0 0,-0 0,-0 0,-0l-0 0z" />
            <path fill="#5C5457" fillRule="nonzero" d="M18.65 8.44c0,0 0,-0 0,-0 0,-0 0,-0 0,-0l-0.01 0z" />
            <path fill="#4C4245" fillRule="nonzero" d="M39.85 5.99l-1.84 0.27c-0.09,0.01 -0.17,-0.05 -0.18,-0.14l-0.07 -0.52c-0.01,-0.09 0.05,-0.17 0.14,-0.18l1.84 -0.27c0.09,-0.01 0.17,0.05 0.18,0.14l0.07 0.52c0.01,0.09 -0.05,0.17 -0.14,0.18z" />
            <path fill="#4C4245" fillRule="nonzero" d="M37.5 5.63l0.07 0.52c0.01,0.09 -0.05,0.17 -0.14,0.18l-1.05 0.15c-0.09,0.01 -0.17,-0.05 -0.18,-0.14l-0.07 -0.52c-0.01,-0.09 0.05,-0.17 0.14,-0.18l1.05 -0.15c0.09,-0.01 0.17,0.05 0.18,0.14z" />
            <path fill="#4C4245" fillRule="nonzero" d="M39.54 5.34l0.07 0.52c0.01,0.09 0.1,0.15 0.18,0.14l1.04 -0.15c-0.29,-0.3 -0.65,-0.53 -1.04,-0.72l-0.11 0.02c-0.09,0.01 -0.15,0.1 -0.14,0.18z" />
            <polygon fill="#4C4C49" fillRule="nonzero" points="21.04,10.43 21.14,11.12 19.44,11.36 18.62,10.78 " />
            <path fill="#41927C" fillRule="nonzero" d="M19.68 11.19l2.61 -0.37c0.49,-0.07 0.97,0.41 1.06,1.06 0.09,0.66 -0.23,1.25 -0.72,1.32l-2.61 0.37c-1.09,0.15 -2.04,-0.25 -2.14,-0.91 -0.09,-0.66 0.71,-1.31 1.8,-1.47z" />
            <path fill="#4C4C49" fillRule="nonzero" d="M21.78 12.11c0.06,0.43 0.33,0.76 0.65,0.81 0.05,0.01 0.11,0.01 0.16,0 0.37,-0.05 0.62,-0.5 0.55,-1 -0.07,-0.5 -0.43,-0.86 -0.81,-0.81 -0.05,0.01 -0.11,0.02 -0.15,0.05 -0.29,0.14 -0.46,0.53 -0.4,0.96z" />
            <path fill="#3C3C3B" fillRule="nonzero" d="M21.78 12.11c0.06,0.43 0.33,0.76 0.65,0.81 0.29,-0.14 0.46,-0.53 0.4,-0.96 -0.06,-0.43 -0.33,-0.76 -0.65,-0.81 -0.29,0.14 -0.46,0.53 -0.4,0.96z" />
            <polygon fill="#4C4C49" fillRule="nonzero" points="14.47,11.04 14.56,11.73 12.86,11.97 12.04,11.38 " />
            <path fill="#41927C" fillRule="nonzero" d="M13.1 11.8l2.61 -0.37c0.49,-0.07 0.97,0.41 1.06,1.06 0.09,0.66 -0.23,1.25 -0.72,1.32l-2.61 0.37c-1.09,0.15 -2.04,-0.25 -2.14,-0.91 -0.09,-0.66 0.71,-1.31 1.8,-1.47z" />
            <path fill="#4C4C49" fillRule="nonzero" d="M15.2 12.72c0.06,0.43 0.33,0.76 0.65,0.81 0.05,0.01 0.11,0.01 0.16,0 0.37,-0.05 0.62,-0.5 0.55,-1 -0.07,-0.5 -0.43,-0.86 -0.81,-0.81 -0.05,0.01 -0.1,0.02 -0.15,0.05 -0.29,0.14 -0.46,0.53 -0.4,0.95z" />
            <path fill="#3C3C3B" fillRule="nonzero" d="M15.2 12.72c0.06,0.43 0.33,0.76 0.65,0.81 0.29,-0.14 0.46,-0.53 0.4,-0.96 -0.06,-0.43 -0.34,-0.76 -0.65,-0.81 -0.29,0.14 -0.46,0.53 -0.4,0.95z" />
            <path fill="#E0E1DD" fillRule="nonzero" d="M1.26 11.43l0.34 0.28 0.29 0.23 0.32 0.26 16.18 -1.29 0.26 -0.02 0.73 -0.06 1.03 -0.08 9.55 -0.76c0,0 -1.13,-1.38 -5.44,-0.78 -0.83,0.12 -2.43,0.29 -4.48,0.49 -0.33,0.03 -0.67,0.07 -1.02,0.1 -0.24,0.02 -0.48,0.05 -0.73,0.07 -0.08,0.01 -0.17,0.02 -0.26,0.02 -8.96,0.86 -16.77,1.54 -16.77,1.54z" />
            <polygon fill="#EF6F5A" fillRule="nonzero" points="2.21,12.19 -0,10.43 1.06,10.28 4.93,11.97 " />
            <path fill="#A7C876" fillRule="nonzero" d="M13.05 7.6c0,0 0,0 0,0l0.24 -0.03 0 0 -0.24 0.03z" />
            <polygon fill="#EFEEEC" fillRule="nonzero" points="9.45,8.11 9.5,8.1 9.58,8.09 9.94,8.04 9.94,8.04 9.45,8.11 " />
            <path fill="#A7C876" fillRule="nonzero" d="M13.31 7.56c0,0 0,0 0,-0l0.94 -0.13c0,0 0,0 0,0l-0.94 0.13z" />
            <path fill="#FEFEFE" fillRule="nonzero" d="M9.45 8.11l0.5 -0.07 1.7 -0.24 0.04 0.31 -2.24 0zm2.49 -0l-0.05 -0.34 1.16 -0.16 0.24 -0.03c0,0 0,0 0,0.01 0.01,-0 0.01,-0.01 0.02,-0.01l0.94 -0.13c0.23,0.06 0.45,0.19 0.64,0.37 -0.75,0.11 -1.47,0.21 -2.14,0.3l-0.02 0 -0.79 0z" />
            <path fill="#FEFEFE" fillRule="nonzero" d="M12.75 8.1c0.67,-0.1 1.39,-0.2 2.14,-0.3 0.09,0.09 0.18,0.19 0.26,0.3l-1.6 0 -0.8 0z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;