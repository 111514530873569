import React from "react";
import styled from "styled-components";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import AddImage1 from "../../assets/img/freight/pic1.png";
import AddImage2 from "../../assets/img/freight/pic4.png";
import AddImage3 from "../../assets/img/freight/pic3.png";
import AddImage4 from "../../assets/img/freight/pic2.png";
import CS1 from "../../assets/img/cs1.jpg";
import CS2 from "../../assets/img/cs4.jpg";
import CS3 from "../../assets/img/cs3.jpg";
import CS4 from "../../assets/img/cs2.jpg";
import Cloud from '../../assets/img/cloud.webp'
import Port from '../../assets/img/port.webp'
import commstyles from "../../style/module/common.module.css";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font15">
              We proudly serve clients across almost all regions of Indonesia, providing tailored logistics and cloud seeding materials production solutions 
              that address the unique needs of businesses in diverse locations. 
              Our extensive network and local expertise enable us to offer reliable and responsive services nationwide.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper className={`${commstyles['serv-box']}`}>
              <ServiceBox
                icon="AirFreight"
                title="Air Freight"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper className={`${commstyles['serv-box']}`}>
              <ServiceBox
                icon="OceanFreight"
                title="Ocean Freight"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper className={`${commstyles['serv-box']}`}>
              <ServiceBox
                icon="RoadFreight"
                title="Road Freight"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper className={`${commstyles['serv-box']}`}>
              <ServiceBox
                icon="MultimodalFreight"
                title="Multimodal Transport"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper className={`${commstyles['serv-box']}`}>
              <ServiceBox
                icon="SaltProduction"
                title="Cloud Seeding Materials Productions"
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>

        <div className={`${commstyles['container-ads']}`}>
          <img src={Port} alt='' className={`${commstyles['img-bg']}`} />
          <div className={`${commstyles['cloud-seeding-container']}`}>
            <div className={`${commstyles['row']} ${commstyles['fx-rev']}`}>
              <div className={`${commstyles['col-2']}`}>
                <div className={`${commstyles['col-1']} ${commstyles['sec-desc-right']}`}>
                  <h4 className="font30 extraBold">Logistics That Move You Forward</h4>
                  <p className="font15">
                    We provide comprehensive logistics solutions designed to streamline your operations.
                  </p>
                </div>
              </div>
              <div className={`${commstyles['col-2']} ${commstyles['fx-c']}`}>

                <div className={`${commstyles['sec-img-right']}`}>
                  <div className={`${commstyles['row-img']}`}>
                    <img src={AddImage1} className={`${commstyles['sec-left-img1']}`} alt="office" />
                    <img src={AddImage2} className={`${commstyles['sec-left-img2']}`} alt="office" />
                  </div>
                  <div className={`${commstyles['row-img']} ${commstyles['mob-ns']}`}>
                    <img src={AddImage3} className={`${commstyles['sec-left-img3']}`} alt="office" />
                    <img src={AddImage4} className={`${commstyles['sec-left-img4']}`} alt="office" />
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>

        <div className={`${commstyles['container-ads']}`}>
          <img src={Cloud} alt='' className={`${commstyles['img-bg']}`} />
          <div className={`${commstyles['cloud-seeding-container']}`}>
            <div className={`${commstyles['row']}`}>
              <div className={`${commstyles['col-2']} ${commstyles['fx-c']} `}>
                <div className={`${commstyles['sec-img-left']}`}>
                  <div className={`${commstyles['row-img']}`}>
                    <img src={CS1} className={`${commstyles['sec-left-img1']}`} alt="office" />
                    <img src={CS2} className={`${commstyles['sec-left-img2']}`} alt="office" />
                  </div>
                  <div className={`${commstyles['row-img']} ${commstyles['mob-ns']}`}>
                    <img src={CS3} className={`${commstyles['sec-left-img3']}`} alt="office" />
                    <img src={CS4} className={`${commstyles['sec-left-img4']}`} alt="office" />
                  </div>
                </div>
              </div>
              <div className={`${commstyles['col-2']}`}>
                <div className={`${commstyles['col-1']} ${commstyles['sec-desc-right']}`}>
                  <h4 className="font30 extraBold">Quality Materials for Reliable Weather Solutions</h4>
                  <p className="font15">
                    We combine advanced manufacturing techniques with a reliable supply chain to deliver effective solutions for weather modification and water resource management.
                  </p>
                  <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                    <div style={{ width: "190px" }}>
                      <a href="#about"><FullButton title="Next" /></a>
                    </div>
                    <div style={{ width: "190px", marginLeft: "15px" }}>
                      <a href="#contact">
                        <FullButton title="Contact Us" border />
                      </a>
                    </div>
                  </ButtonsRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;