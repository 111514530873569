import * as React from "react";

function SvgComponent(props) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="1.07616in" height="0.718402in" viewBox="0 0 56.29 37.58" {...props} >
    <svg xmlns="http://www.w3.org/2000/svg" width="1.07616in" height="0.718402in" viewBox="0 0 56.29 37.58" {...props} >
      <g id="Layer_x0020_1">
        <g id="_2816373861120">
          <path fill="#42729F" d="M14.98 17.39l-0 1.68 0.02 4.02 0 1.68 -0.01 2.61c-0.98,0 -2.05,0.17 -2.68,-0.44 -0.4,-0.39 -0.78,-1.39 -0.89,-2.06l-0.68 -1.79c-0.57,-0.7 -1.03,-0.77 -2.17,-0.77l-6.6 -0c-0.84,-0 -1.53,-0.12 -1.7,0.61 -0.08,0.35 0.29,1.23 0.41,1.67 0.4,1.44 1.34,3.87 1.63,5.21 0.68,0.16 1.79,1.04 3.47,-0.18 3.64,-2.66 3.63,0.92 6.54,0.61 1.49,-0.16 1.72,-1.3 3.11,-1.51 1.73,-0.26 2.19,0.71 3.21,1.24 2.83,1.46 3.96,-2.68 6.77,-0.83 0.88,0.58 1.71,1.57 3.39,0.98 1.14,-0.4 1.44,-1.44 3.09,-1.42 1.59,0.02 1.87,1.07 3.06,1.44 2.93,0.9 3.56,-3.15 6.76,-0.71 0.89,0.68 2.02,1.25 3.39,0.5 0.97,-0.53 1.52,-1.5 3.23,-1.18 1.33,0.25 1.55,1.33 3.02,1.5 1.89,0.21 2.12,-0.91 3.3,-1.33l0.18 -0.37c0.35,-1.06 1.6,-4.63 1.46,-5.32 -0.2,-0.95 -1.13,-0.9 -1.63,-0.91l-3.79 0 -11.93 0c-1.91,0 -1.84,2.77 -2.9,4.36 -0.36,0.54 -0.87,0.7 -1.72,0.7l-6.43 0 0.03 -2.61 -0 -1.69 0 -4.04 -0.04 0.01 -0.01 4.03 -0 1.68 0.01 2.61 -6.41 0 -6.44 0 0.06 -2.61 -0 -1.69 -0.03 -4.02 -0.01 -1.69 -0.02 -4.04 -0.03 -0.02 0.01 4.07z" />
          <path fill="#0FD8FF" d="M53.65 32.34c-0.51,0.83 -2.03,1.16 -3.2,0.57 -1.04,-0.53 -1.36,-1.49 -3.17,-1.24 -1.33,0.18 -1.6,1.22 -2.93,1.48 -1.59,0.31 -2.21,-0.55 -3.06,-1.09 -1.2,-0.75 -2.39,-0.48 -3.36,0.27 -3.27,2.52 -3.74,-0.89 -6.37,-0.68 -1.43,0.12 -1.72,1.11 -2.88,1.44 -2.81,0.81 -3.63,-2.98 -6.56,-0.85 -3.52,2.57 -3.71,-0.59 -6.17,-0.61 -2.61,-0.02 -2.61,3.18 -6.2,0.63 -3.09,-2.18 -3.84,1.65 -6.44,0.83 -1.04,-0.19 -1.88,-1.54 -3.01,-1.49 -0.16,0.01 -0.24,0.09 -0.29,0.24l0 1.7c0,0.77 0,1.54 0,2.31 0.63,0.3 0.81,-0.02 1.79,0.74 4.04,3.1 4.4,-2.71 8.12,0.13 0.26,0.2 0.47,0.37 0.79,0.53 2.55,1.3 3.33,-1.79 5.86,-1.24 1.46,0.32 2.59,2.83 5.38,0.68 2.63,-2.02 3.84,0.54 5.35,0.79 2.01,0.34 2.42,-1.02 3.69,-1.43 0.7,-0.22 1.59,-0.1 2.15,0.2 0.68,0.37 1.89,1.86 3.74,1.06 0.71,-0.31 0.91,-0.65 1.52,-1.01 2.53,-1.48 3.38,1.48 5.8,1.18 1.52,-0.19 1.8,-1.54 3.53,-1.54 1.95,0 2.06,1.42 3.63,1.55 2.15,0.18 2.22,-1.15 3.6,-1.45 0.91,-0.2 0.8,0.24 0.79,-1.74 -0,-0.88 0,-1.76 0,-2.64 -1.06,-0.25 -1.55,0.52 -2.1,0.66z" />
          <path fill="#355D7B" d="M2.31 29.83c0.12,0.23 0.96,2.93 0.99,3.26 2.61,0.82 3.36,-3.01 6.44,-0.83 3.59,2.54 3.59,-0.65 6.2,-0.63 2.46,0.02 2.65,3.17 6.17,0.61 2.93,-2.13 3.75,1.66 6.56,0.85 1.16,-0.33 1.45,-1.33 2.88,-1.44 2.63,-0.21 3.1,3.21 6.37,0.68 0.97,-0.75 2.16,-1.02 3.36,-0.27 0.86,0.53 1.48,1.4 3.06,1.09 1.33,-0.26 1.6,-1.3 2.93,-1.48 1.8,-0.24 2.13,0.72 3.17,1.24 1.17,0.59 2.69,0.26 3.2,-0.57 0.06,-0.5 0.35,-1.19 0.52,-1.69 0.15,-0.45 0.44,-1.27 0.48,-1.72 -1.18,0.42 -1.41,1.53 -3.3,1.33 -1.46,-0.16 -1.69,-1.25 -3.02,-1.5 -1.72,-0.32 -2.26,0.65 -3.23,1.18 -1.38,0.76 -2.5,0.18 -3.39,-0.5 -3.2,-2.44 -3.83,1.61 -6.76,0.71 -1.19,-0.37 -1.48,-1.42 -3.06,-1.44 -1.65,-0.02 -1.95,1.02 -3.09,1.42 -1.68,0.59 -2.51,-0.4 -3.39,-0.98 -2.81,-1.85 -3.94,2.3 -6.77,0.83 -1.02,-0.53 -1.48,-1.49 -3.21,-1.24 -1.39,0.21 -1.62,1.35 -3.11,1.51 -2.91,0.31 -2.9,-3.27 -6.54,-0.61 -1.68,1.23 -2.79,0.34 -3.47,0.18z" />
          <path fill="#E5F4FF" d="M46.46 13.86c1.38,-0.49 1.38,2.52 0.5,2.88 -0.85,0.36 -1.15,-0.39 -1.16,-1.11 -0.02,-0.97 -0.13,-1.49 0.66,-1.77zm-2.76 -0.01c0.74,-0.22 1.01,0.42 1.02,1.11 0.01,0.81 0.12,1.63 -0.58,1.82 -0.87,0.23 -1.11,-0.42 -1.12,-1.15 -0.01,-0.96 -0.14,-1.54 0.67,-1.78zm-2.9 4.68l8.88 0.05 0.36 -0.2c0,-0.47 0.12,-1.26 -0.08,-1.61 -1.26,0.14 -1.31,-0.25 -1.31,-1.46 0,-1.03 0.14,-1.68 1.26,-1.4 0.1,-0.15 0.05,-0.01 0.11,-0.26l0.03 -0.64c-0,-0.3 -0,-0.6 -0,-0.91l-0.23 -0 -9.02 0.01 0 6.43z" />
          <path fill="#FF6868" d="M38.92 22.33l11.93 -0c0,-0.66 0.14,-2.65 -0.18,-3.17l-0.39 -0.41c-0.11,-0.11 -0.1,-0.1 -0.18,-0.2l-0.44 0.03 -8.88 -0.05c-0.97,-0.13 -1.7,0.27 -1.85,0.97 -0.1,0.49 -0.02,2.22 -0.02,2.84z" />
          <path fill="#6193FE" d="M14.95 11.71l6.49 -0.03c0,-1.52 0.19,-3.78 -0.11,-5.07 -0.8,-0.19 -4.69,-0.24 -5.71,-0.11 -0.92,0.12 -0.66,1.15 -0.66,2.19 0,1 0,2.01 0,3.01z" />
          <polygon fill="#FFDA06" points="21.44,13.37 21.44,17.39 27.93,17.42 27.93,12.88 27.57,12.57 21.44,12.55 " />
          <path fill="#FFDA06" d="M8.58 17.39l6.41 0 -0.01 -4.07 -0 -0.77c-1.17,0 -5.62,-0.21 -6.28,0.11 -0.28,0.57 -0.11,3.77 -0.11,4.73z" />
          <path fill="#6193FE" d="M27.9 19.06l-0 4.04 6.41 0 0.01 -4.18c-0.03,-0.58 -0.2,-0.64 -0.77,-0.66 -1.82,-0.06 -3.79,0.01 -5.63,0.01l-0.01 0.79z" />
          <path fill="#C4D9EB" d="M45.92 9.03c0.37,0.19 1.32,0.03 1.8,0.03 -0.3,-0.85 -0.11,-2.07 -0.11,-3 0.62,0 1.27,0.02 1.88,0.01 0.63,-0.01 1.01,-0.24 0.99,-0.88 -0.03,-1.19 -1.69,-0.88 -2.87,-0.88l0 -0.99c0.9,0 1.67,0.1 1.65,-0.88 -0.01,-0.36 -0.22,-0.61 -0.47,-0.74 -0.35,-0.19 -0.69,-0.14 -1.18,-0.14 0,-0.54 0.17,-1.58 -0.87,-1.55 -0.83,0.02 -0.89,0.7 -0.89,1.55 -2.46,0 -1.44,1.53 -1.22,1.66 0.26,0.15 0.79,0.1 1.22,0.1l0 0.99c-1.22,0 -2.79,-0.25 -2.83,0.89 -0,0.17 0.12,0.48 0.23,0.61 0.38,0.44 1.73,0.26 2.6,0.26 0,0.88 -0.13,2.16 0.07,2.97z" />
          <polygon fill="#6193FE" points="15.02,17.39 21.44,17.39 21.44,13.37 15.01,13.34 " />
          <polygon fill="#FFDA06" points="21.44,23.09 27.85,23.09 27.86,19.07 21.44,19.08 " />
          <polygon fill="#6193FE" points="15.06,23.09 21.44,23.09 21.44,19.08 15.03,19.07 " />
          <path fill="#FF6868" d="M40.79 12.09l9.02 -0.01c-0.02,-0.03 -0.04,-0.1 -0.05,-0.08l-0.14 -0.35c-0.06,-0.21 -0.06,-0.32 -0.13,-0.54 -0.31,-0.92 -1.06,-1.63 -2.03,-1.89 0.52,0.07 0.03,0.02 0.25,-0.16 -0.47,0 -1.43,0.16 -1.8,-0.03 -1.4,0 -3.21,-0.15 -4.14,0.33 -0.91,0.47 -1.65,1.35 -1.65,2.75l0.66 -0.01z" />
          <path fill="#FFDA06" d="M8.58 22.33c1.14,0 1.6,0.07 2.17,0.77l4.25 -0.01 -0.02 -4.02 -6.4 0 0 3.26z" />
          <polygon fill="#6193FE" points="34.31,27.39 34.31,24.78 27.9,24.78 27.87,27.39 " />
          <polygon fill="#6193FE" points="21.44,27.39 21.44,24.78 15.07,24.78 15,27.39 " />
          <polygon fill="#FFDA06" points="21.44,27.39 27.85,27.39 27.85,24.78 21.44,24.78 " />
          <path fill="#C4D9EB" d="M49.9 13.89c0.49,0.39 0.44,0.58 0.44,1.4 0,1.03 0.06,0.73 -0.39,1.46 0.2,0.36 0.08,1.14 0.08,1.61l-0.36 0.2 0.44 -0.03c0.62,0.02 2.08,0.15 2.52,-0.06l0.03 -6.4 -2.63 0c0,0.3 0,0.6 0,0.91l-0.03 0.64c-0.06,0.25 -0.01,0.11 -0.11,0.26z" />
          <path fill="#FF4848" d="M50.11 18.55c0.08,0.1 0.08,0.09 0.18,0.2l0.39 0.41c0.31,0.52 0.18,2.52 0.18,3.17l3.79 -0c0,-0.64 0.08,-2.3 -0.04,-2.82 -0.09,-0.42 -0.33,-0.63 -0.62,-0.81 -0.42,-0.26 -0.69,-0.22 -1.36,-0.22 -0.44,0.21 -1.91,0.08 -2.52,0.06z" />
          <path fill="#FF4848" d="M49.81 12.08l0.23 0 2.63 -0 0.77 0.03c0,-1.32 -0.79,-2.3 -1.65,-2.75 -0.74,-0.39 -2.91,-0.5 -4.07,-0.3 -0.22,0.19 0.27,0.23 -0.25,0.16 0.97,0.25 1.72,0.96 2.03,1.89 0.07,0.22 0.07,0.33 0.13,0.54l0.14 0.35c0.01,-0.02 0.03,0.06 0.05,0.08z" />
          <polygon fill="#407BFF" points="15.03,19.07 21.44,19.08 21.44,17.39 15.02,17.39 " />
          <polygon fill="#407BFF" points="27.9,24.78 34.31,24.78 34.31,23.09 27.9,23.09 " />
          <polygon fill="#FFAB1E" points="21.44,24.78 27.85,24.78 27.85,23.09 21.44,23.09 " />
          <polygon fill="#FFAB1E" points="8.58,19.07 14.98,19.07 14.98,17.39 8.58,17.39 " />
          <polygon fill="#FFAB1E" points="21.44,19.08 27.86,19.07 27.9,19.06 27.91,18.26 27.93,17.42 21.44,17.39 " />
          <polygon fill="#407BFF" points="15.07,24.78 21.44,24.78 21.44,23.09 15.06,23.09 " />
          <polygon fill="#407BFF" points="14.97,12.55 14.97,13.32 15.01,13.34 21.44,13.37 21.44,12.55 21.44,11.68 14.95,11.71 " />
          <path fill="#FFDA06" d="M15 24.78c-0.48,0 -3.17,-0.13 -3.58,0.12 0.11,0.67 0.48,1.67 0.89,2.06 0.62,0.6 1.69,0.44 2.68,0.44l0.01 -2.61z" />
          <path fill="#FFAB1E" d="M11.42 24.89c0.41,-0.25 3.1,-0.12 3.58,-0.12l-0 -1.68 -4.25 0.01 0.68 1.79z" />
          <path fill="#10D9FF" d="M43.69 13.85c-0.82,0.24 -0.68,0.82 -0.67,1.78 0.01,0.72 0.25,1.38 1.12,1.15 0.7,-0.19 0.59,-1.01 0.58,-1.82 -0.01,-0.69 -0.28,-1.33 -1.02,-1.11z" />
          <path fill="#0FD8FF" d="M46.46 13.86c-0.79,0.28 -0.68,0.81 -0.66,1.77 0.02,0.73 0.31,1.47 1.16,1.11 0.88,-0.37 0.87,-3.37 -0.5,-2.88z" />
          <path fill="#10D9FF" d="M49.95 16.76c0.44,-0.73 0.39,-0.43 0.39,-1.46 -0,-0.83 0.05,-1.02 -0.44,-1.4 -1.12,-0.28 -1.26,0.38 -1.26,1.4 0,1.21 0.05,1.61 1.31,1.46z" />
        </g>
      </g>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width={43.336} height={32.531} viewBox="0 0 43.336 32.531" {...props}>
    //   <g data-name="Group 71">
    //     <path
    //       data-name="Path 123"
    //       d="M38.562 24.112H4.778a3.428 3.428 0 01-3.414-3.406V4.776a3.424 3.424 0 013.409-3.412h33.784a3.425 3.425 0 013.41 3.41V20.7a3.425 3.425 0 01-3.407 3.412z"
    //       fill="#f40051"
    //     />
    //     <path
    //       data-name="Path 124"
    //       d="M38.559 0H4.778A4.785 4.785 0 000 4.776v15.922a4.786 4.786 0 004.778 4.778h13.507v4.322h-3.926a.684.684 0 00-.681.683v1.366a.684.684 0 00.681.683h14.619a.685.685 0 00.682-.683v-1.366a.685.685 0 00-.682-.683h-3.926v-4.322H38.56a4.786 4.786 0 004.776-4.778V4.776A4.785 4.785 0 0038.559 0zM22.321 29.8h-1.308v-4.322h1.308zm18.283-9.1a2.068 2.068 0 01-2.045 2.048H4.778a2.07 2.07 0 01-2.05-2.048V4.778a2.069 2.069 0 012.048-2.047h33.782a2.069 2.069 0 012.045 2.047z"
    //       fill="#0b093b"
    //     />
    //   </g>
    // </svg>
  );
}

export default SvgComponent;