import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="“We are truly impressed with PT. Pacific Multi Jaya’s exceptional service. Their team goes above and beyond to ensure that our logistics needs are met with precision and efficiency. Their dedication to excellence has made a significant impact on our operations.”"
            author="Smartaviation"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="“The professionalism and efficiency of PT. Pacific Multi Jaya have been a tremendous asset to our business. Their team’s expertise and responsive service have exceeded our expectations, making them a trusted partner in our logistics management.”"
            author="Jasa Dinamika Mmandiri"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="PT. Pacific Multi Jaya’s dedication to delivering top-notch logistics solutions has been a key factor in our operational success. Their team’s reliability and expertise have made a real difference, and we are grateful for their continued support."
            author="Asia Pacific Aero Support"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
