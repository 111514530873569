import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}


const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#00AFEF")};
  background-color: ${(props) => (props.border ? "transparent" : "#00AFEF")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0c6fd2")};
    border: 1px solid #00AFEF;
    color: ${(props) => (props.border ? "#00AFEF" : "#fff")};
  }
`;

