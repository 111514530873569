import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.03452in" height="0.973768in" viewBox="0 0 59 56" >
      <g id="Layer_x0020_1">
        <g id="_1725804445008">
          <polygon fill="#F4D8B9" fill-rule="nonzero" points="31,55 59,39 28,21 0,37 " />
          <polygon fill="#E1BCA7" fill-rule="nonzero" points="31,55 31,56 59,40 59,39 " />
          <polygon fill="#CDA497" fill-rule="nonzero" points="0,37 0,37 31,56 31,55 " />
          <polygon fill="#CDA497" fill-rule="nonzero" points="38,35 52,43 47,46 33,38 " />
          <polygon fill="#CDA497" fill-rule="nonzero" points="45,31 58,39 53,42 40,34 " />
          <path fill="#E0E4E4" fill-rule="nonzero" d="M30 1l0 20c0,0 -1,1 -1,1 -1,0 -1,0 -1,-1l0 -20 2 0z" />
          <path fill="#F9FCFE" fill-rule="nonzero" d="M27 1c0,0 1,1 1,1 1,0 1,0 1,-1 0,0 -1,-1 -1,-1 -1,0 -1,0 -1,1z" />
          <path fill="#848588" fill-rule="nonzero" d="M27 1c0,0 0,0 1,0 1,0 1,0 1,0 0,0 0,0 -1,0 -1,0 -1,0 -1,0z" />
          <path fill="#5F7EBE" fill-rule="nonzero" d="M28 10c-1,0 -1,0 -1,0l0 -6c0,0 1,1 1,1 1,0 1,0 1,-1l0 6c0,0 -1,0 -1,0z" />
          <path fill="#E0E4E4" fill-rule="nonzero" d="M26 3l0 20c0,0 -1,1 -1,1 -1,0 -1,0 -1,-1l0 -20 2 0z" />
          <path fill="#F9FCFE" fill-rule="nonzero" d="M23 3c0,0 1,1 1,1 1,0 1,0 1,-1 0,0 -1,-1 -1,-1 -1,0 -1,0 -1,1z" />
          <path fill="#848588" fill-rule="nonzero" d="M23 3c0,0 0,0 1,0 1,0 1,0 1,0 0,0 0,0 -1,0 -1,0 -1,0 -1,0z" />
          <path fill="#5F7EBE" fill-rule="nonzero" d="M24 12c-1,0 -1,0 -1,0l0 -6c0,0 1,1 1,1 1,0 1,0 1,-1l0 6c0,0 -1,0 -1,0z" />
          <path fill="#E0E4E4" fill-rule="nonzero" d="M21 5l0 20c0,0 -1,1 -1,1 -1,0 -1,0 -1,-1l0 -20 2 0z" />
          <path fill="#F9FCFE" fill-rule="nonzero" d="M19 5c0,0 1,1 1,1 1,0 1,0 1,-1 0,0 -1,-1 -1,-1 -1,0 -1,0 -1,1z" />
          <path fill="#848588" fill-rule="nonzero" d="M19 5c0,0 0,0 1,0 1,0 1,0 1,0 0,0 0,0 -1,0 -1,0 -1,0 -1,0z" />
          <path fill="#5F7EBE" fill-rule="nonzero" d="M20 14c-1,0 -1,0 -1,0l0 -6c0,0 1,1 1,1 1,0 1,0 1,-1l0 6c0,0 -1,0 -1,0z" />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="26,35 11,26 11,34 26,42 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="31,15 11,26 26,35 45,23 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="45,23 26,35 26,42 45,31 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="20,28 11,23 11,26 20,31 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="23,16 11,23 20,28 31,22 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="31,22 20,28 20,31 31,25 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="20,25 11,20 11,21 20,27 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="23,13 11,20 20,25 31,18 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="31,18 20,25 20,27 31,20 " />
          <polygon fill="#5B6A7C" fill-rule="nonzero" points="20,27 12,22 12,23 20,28 " />
          <polygon fill="#657282" fill-rule="nonzero" points="31,20 20,27 20,28 31,22 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="22,13 12,19 20,24 30,18 " />
          <polygon fill="#E2E5E5" fill-rule="nonzero" points="12,19 11,20 20,25 20,24 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="20,24 20,25 31,18 30,18 " />
          <polygon fill="#E2E5E5" fill-rule="nonzero" points="30,18 31,18 23,13 22,13 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="11,20 12,19 22,13 23,13 " />
          <polygon fill="#F1F1F3" fill-rule="nonzero" points="40,34 44,31 45,31 40,34 " />
          <polygon fill="#737A7A" fill-rule="nonzero" points="44,31 44,27 40,30 40,34 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="45,27 45,31 44,31 44,27 " />
          <polygon fill="#F1F1F3" fill-rule="nonzero" points="33,37 38,35 38,35 33,38 " />
          <polygon fill="#9D7877" fill-rule="nonzero" points="38,35 38,31 33,33 33,37 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="38,30 38,35 38,35 38,31 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="14,36 14,39 16,38 16,35 " />
          <polygon fill="#7C697F" fill-rule="nonzero" points="14,36 14,39 7,35 7,32 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="13,37 13,37 11,36 11,35 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="13,38 13,38 12,38 12,37 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="16,35 14,36 7,32 10,31 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="11,39 11,41 13,40 13,37 " />
          <polygon fill="#7C697F" fill-rule="nonzero" points="11,39 11,41 4,37 4,35 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="10,39 10,40 8,38 8,37 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="10,40 10,41 8,40 8,39 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="13,37 11,39 4,35 6,33 " />
          <polygon fill="#657282" fill-rule="nonzero" points="14,31 14,30 12,29 12,30 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="12,29 13,29 13,30 12,30 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="13,30 14,31 14,31 12,30 " />
          <polygon fill="#657282" fill-rule="nonzero" points="17,32 17,31 15,30 15,31 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="15,30 15,30 15,31 15,31 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="15,31 17,32 17,32 15,31 " />
          <polygon fill="#657282" fill-rule="nonzero" points="19,34 19,33 17,32 17,33 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="17,32 18,32 18,33 17,33 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="18,33 19,34 19,34 17,33 " />
          <polygon fill="#657282" fill-rule="nonzero" points="22,35 22,34 20,33 20,34 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="20,33 20,33 20,34 20,34 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="20,34 22,35 22,35 20,34 " />
          <polygon fill="#657282" fill-rule="nonzero" points="24,37 24,36 22,35 22,36 " />
          <polygon fill="#E0E4E4" fill-rule="nonzero" points="22,35 23,35 23,36 22,36 " />
          <polygon fill="#F9FCFE" fill-rule="nonzero" points="23,36 24,37 24,37 22,36 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="40,23 43,21 43,22 40,23 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="28,16 40,23 40,23 28,16 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="31,14 28,16 40,23 43,21 " />
          <polygon fill="#7C697F" fill-rule="nonzero" points="28,16 28,16 40,23 40,23 " />
          <polygon fill="#CDA497" fill-rule="nonzero" points="54,36 26,52 31,55 59,39 " />
          <polygon fill="#C29089" fill-rule="nonzero" points="26,52 31,55 31,56 26,53 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="22,41 22,44 25,43 25,40 " />
          <polygon fill="#7C697F" fill-rule="nonzero" points="22,41 22,44 16,40 16,37 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="22,41 22,42 20,41 20,40 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="22,43 22,43 20,42 20,42 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="25,40 22,41 16,37 18,36 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="19,43 19,46 21,45 21,42 " />
          <polygon fill="#7C697F" fill-rule="nonzero" points="19,43 19,46 12,42 12,39 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="18,44 18,44 16,43 16,42 " />
          <polygon fill="#C6DCE7" fill-rule="nonzero" points="18,45 18,45 17,45 17,44 " />
          <polygon fill="#978E9C" fill-rule="nonzero" points="21,42 19,43 12,39 14,38 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="25,30 28,32 26,32 22,30 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="22,30 22,31 26,33 26,32 " />
          <polygon fill="#E2E5E5" fill-rule="nonzero" points="26,32 26,33 28,32 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="30,27 33,29 31,29 27,27 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="27,27 27,28 31,30 31,29 " />
          <polygon fill="#E2E5E5" fill-rule="nonzero" points="31,29 31,30 33,29 " />
          <polygon fill="#847B8D" fill-rule="nonzero" points="35,24 39,26 36,26 32,24 " />
          <polygon fill="#D4D4D4" fill-rule="nonzero" points="32,24 32,25 36,27 36,26 " />
          <polygon fill="#E2E5E5" fill-rule="nonzero" points="36,26 36,27 39,26 " />
          <polygon fill="#9D7877" fill-rule="nonzero" points="44,28 44,27 40,30 40,31 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="42,21 41,21 40,22 40,22 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="41,21 41,20 39,21 39,22 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="40,20 40,20 38,21 39,21 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="40,20 39,20 37,21 38,21 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="39,19 38,19 37,20 37,20 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="38,19 38,19 36,20 36,20 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="37,18 37,18 35,19 36,19 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="37,18 36,18 34,19 35,19 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="36,18 35,17 34,18 34,19 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="35,17 35,17 33,18 33,18 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="34,17 34,16 32,17 33,18 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="34,16 33,16 31,17 32,17 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="33,16 32,16 31,17 31,17 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="32,15 32,15 30,16 30,16 " />
          <polygon fill="#655B63" fill-rule="nonzero" points="31,15 31,15 29,16 30,16 " />
          <g>
            <path fill="#98A3B3" fill-rule="nonzero" d="M31 40l1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M33 41c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M31 40c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M31 40c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M32 40c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M34 41c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 41c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M33 41c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 41c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 41c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M34 41c0,0 0,-1 0,-1l-1 0 0 0 -1 -1c0,0 0,0 0,0l0 0 -1 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 1,1 2,1 0,0 0,0 0,0 0,0 0,1 0,1l1 0 0 -1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M31 39c0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 40l-1 0 0 0 -1 -1c0,0 0,0 0,0l0 1 0 0 0 -1c0,0 0,0 0,0l2 1 0 0 0 0c0,0 0,0 0,1l0 1 0 0 0 -1c0,0 0,-1 0,-1z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="34,41 35,40 36,41 35,42 " />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 41l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M34 42l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M34 42l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="35,42 36,41 37,42 36,43 " />
            <path fill="#373A4A" fill-rule="nonzero" d="M37 42l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M35 42l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M35 42l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <polygon fill="#F89F32" fill-rule="nonzero" points="36,40 31,37 31,38 31,39 36,42 " />
            <polygon fill="#FFCA31" fill-rule="nonzero" points="33,35 31,37 36,40 39,38 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="33,35 31,37 36,39 38,38 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="38,37 36,39 35,39 36,41 38,40 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="39,37 36,39 36,41 36,41 36,42 39,40 " />
            <path fill="#F58934" fill-rule="nonzero" d="M34 39c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M34 39c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M31 37c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M32 37c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M32 38c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M33 38c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M33 38c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M35 39c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M35 40c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="36,41 31,39 31,39 36,42 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="36,41 36,41 31,39 31,39 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="38,38 33,35 33,37 37,39 " />
            <polygon fill="#FFCA31" fill-rule="nonzero" points="36,39 33,37 33,38 35,39 36,39 " />
            <path fill="#98A3B3" fill-rule="nonzero" d="M36 43l1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M38 43c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M36 43c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M36 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M36 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M37 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M37 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M37 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M36 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M36 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M37 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M37 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M38 42l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 -1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1l1 0 0 -1c0,0 0,-1 0,-1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M36 42c0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 42l-1 0c0,0 0,0 0,0l0 1 0 0 0 -1c0,0 0,0 0,0l1 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 42l-1 0 0 0 0 0 0 0 0 0c0,0 0,0 0,1l0 1 0 0 0 -1c0,0 0,-1 0,-1z" />
            <path fill="#F58934" fill-rule="nonzero" d="M40 39c0,0 0,0 0,-1l-1 -1 0 1 -2 1 1 3 2 -1c0,0 0,-1 0,-1 0,0 0,-1 0,-1z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M36 42l2 1c0,0 0,-1 0,-1 0,0 0,-1 0,-1 0,0 0,0 0,-1l-1 -1 0 2z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M39 38l-2 1 1 1 2 -1c0,0 0,0 0,0 0,0 -1,-1 -1,-1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M38 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,-1 2,-1 0,0 0,0 0,0 0,0 0,0 0,0l-2 1z" />
            <path fill="#30323C" fill-rule="nonzero" d="M40 42l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l-2 1 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,-1 1,-1 0,0 1,0 1,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M38 43c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="39,43 38,43 38,43 39,43 " />
            <polygon fill="#FEFEFE" fill-rule="nonzero" points="39,43 38,43 38,43 39,43 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="39,43 38,43 38,43 38,43 " />
            <path fill="#FFCA31" fill-rule="nonzero" d="M40 42c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="40,42 40,42 40,42 40,42 " />
            <polygon fill="#FEFEFE" fill-rule="nonzero" points="40,42 40,42 40,42 40,42 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="40,42 40,42 40,42 40,42 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="37,39 37,40 37,40 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="38,39 37,40 37,40 38,40 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="38,39 38,40 38,40 38,40 " />
            <polygon fill="#3E3F53" fill-rule="nonzero" points="38,39 37,39 37,39 37,40 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="38,39 39,39 39,39 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="39,38 39,39 39,39 39,39 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="39,39 39,39 39,39 39,39 " />
            <polygon fill="#3E3F53" fill-rule="nonzero" points="39,38 39,38 38,39 39,39 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 41c0,-1 0,-1 -1,-1 0,0 0,0 -1,0l0 1 1 1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M38 41c0,0 0,-1 0,-1 0,0 -1,0 -1,-1l0 0c0,0 0,0 1,0 0,0 0,0 1,1 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,-1z" />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="36,40 37,41 37,41 37,40 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M39 41c0,0 1,0 1,-1 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,1 -2,1 0,0 0,0 0,0 0,0 0,1 0,1 0,0 0,0 1,-1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M40 39c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 -1,0 -1,1 0,0 -1,0 -1,1 0,0 0,0 0,0l2 -1c0,0 0,-1 0,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M40 40c0,0 0,0 0,0l-2 1c0,0 0,0 0,0 1,0 1,0 2,-1 0,0 0,0 0,0z" />
            <path fill="#E4E8F0" fill-rule="nonzero" d="M38 40c0,0 0,0 0,0l2 -1 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,1 -2,1l0 0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M40 39l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#D3D9E2" fill-rule="nonzero" d="M40 40l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M36 41l0 0c0,0 1,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,-1 0,0 0,0 0,0 0,0 -1,-1 -1,-1z" />
            <path fill="#474A60" fill-rule="nonzero" d="M40 41l-2 1c0,0 0,0 0,0 0,0 0,0 0,0l2 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M40 41l-2 1c0,0 0,0 0,0 0,0 0,0 0,0l2 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#F89F32" fill-rule="nonzero" points="36,39 38,38 39,38 37,39 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="36,39 38,37 39,37 37,39 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="37,39 37,39 39,37 39,38 " />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M38 38c0,0 0,0 0,0 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,-1 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 4,2 4,2l0 0c0,0 2,-1 2,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 38c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 4,2 4,2l0 0c0,0 2,-1 2,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 36c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="33,36 33,36 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 36l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 38l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M36 38l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 36c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 37c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M36 38c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 37c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 36l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 37l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M36 37l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 38l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 37l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 37l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 38l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 38l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 37c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 37c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 37c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <line fill="#C9D1E2" fill-rule="nonzero" x1="33" y1="37" x2="33" y2="37" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 37l0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 37l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="33,37 33,37 33,37 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M34 38l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="34,38 34,38 34,38 " />
          </g>
          <g>
            <path fill="#98A3B3" fill-rule="nonzero" d="M27 43l1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M28 43c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M27 43c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M27 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M27 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M27 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M27 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M27 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M27 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M27 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M30 44c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 43c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M28 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M28 43c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M28 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M29 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M29 44c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M29 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 43c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M28 44c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M29 44c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M29 44c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M30 43c0,0 0,-1 0,-1l-1 0 0 0 -1 -1c0,0 0,0 0,0l0 0 -1 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 1,1 2,1 0,0 0,0 0,0 0,0 0,1 0,1l1 0 0 -1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M27 42c0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M29 43l-1 0 0 0 -1 -1c0,0 0,0 0,0l0 1 0 0 0 -1c0,0 0,0 0,0l2 1 0 0 0 0c0,0 0,0 0,1l0 1 0 0 0 -1c0,0 0,-1 0,-1z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="30,44 31,43 32,44 31,45 " />
            <path fill="#373A4A" fill-rule="nonzero" d="M31 44l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M30 44l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M30 44l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="31,44 32,43 33,44 32,45 " />
            <path fill="#373A4A" fill-rule="nonzero" d="M32 44l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M31 45l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M31 45l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <polygon fill="#F89F32" fill-rule="nonzero" points="31,42 26,39 26,40 27,41 31,44 " />
            <polygon fill="#FFCA31" fill-rule="nonzero" points="29,37 26,39 31,42 34,40 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="29,38 26,39 31,42 34,40 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="34,40 31,41 31,42 31,44 34,42 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="34,40 31,42 31,44 31,44 31,44 34,42 " />
            <path fill="#F58934" fill-rule="nonzero" d="M29 41c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M30 41c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M27 39c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M27 40c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M28 40c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M28 40c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M29 41c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M30 42c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M31 42c0,0 0,0 0,0l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1c0,0 0,0 0,0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="31,44 27,41 27,41 31,44 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="31,44 31,44 27,41 27,41 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="34,40 29,38 29,40 33,42 " />
            <polygon fill="#FFCA31" fill-rule="nonzero" points="32,41 29,40 28,40 31,42 31,41 " />
            <path fill="#98A3B3" fill-rule="nonzero" d="M32 45l1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M33 46c0,0 0,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l1 1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 45c0,0 0,1 1,1 0,0 1,0 1,0 0,0 0,-1 -1,-1 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M32 45c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M32 45c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#FEFEFE" fill-rule="nonzero" d="M32 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M32 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M33 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 45c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 45c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M32 46c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M33 45l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 -1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1l1 0 0 -1c0,0 0,-1 0,-1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M32 45c0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M32 45l-1 0c0,0 0,0 0,0l0 1 0 0 0 -1c0,0 0,0 0,0l1 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 45l-1 0 0 0 0 0 0 0 0 0c0,0 0,0 0,1l0 1 0 0 0 -1c0,0 0,-1 0,-1z" />
            <path fill="#F58934" fill-rule="nonzero" d="M36 42c0,0 0,0 0,-1l-1 -1 0 1 -2 1 1 3 2 -1c0,0 0,-1 0,-1 0,0 0,-1 0,-1z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M32 44l2 1c0,0 0,-1 0,-1 0,0 0,-1 0,-1 0,0 0,0 0,-1l-1 -1 0 2z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M34 41l-2 1 1 1 2 -1c0,0 0,0 0,0 0,0 -1,-1 -1,-1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M34 45c0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,-1 2,-1 0,0 0,0 0,0 0,0 0,0 0,0l-2 1z" />
            <path fill="#30323C" fill-rule="nonzero" d="M36 44l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l-2 1 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,-1 1,-1 0,0 1,0 1,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M34 45c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="34,45 34,45 34,46 34,46 " />
            <polygon fill="#FEFEFE" fill-rule="nonzero" points="34,45 34,45 34,46 34,45 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="34,45 34,45 34,45 34,45 " />
            <path fill="#FFCA31" fill-rule="nonzero" d="M35 44c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="36,44 36,44 36,45 36,45 " />
            <polygon fill="#FEFEFE" fill-rule="nonzero" points="36,44 36,44 36,45 36,45 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="36,44 36,44 35,44 36,44 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="32,42 33,42 33,42 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="34,42 33,42 33,42 34,42 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="33,42 33,42 33,42 33,42 " />
            <polygon fill="#3E3F53" fill-rule="nonzero" points="34,42 33,42 32,42 33,42 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="34,41 35,41 35,41 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="35,41 35,41 35,41 35,41 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="35,41 35,41 35,41 35,41 " />
            <polygon fill="#3E3F53" fill-rule="nonzero" points="35,41 34,41 34,41 35,41 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 44c0,-1 0,-1 -1,-1 0,0 0,0 -1,0l0 1 1 1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M33 43c0,0 0,-1 0,-1 0,0 -1,0 -1,-1l0 0c0,0 0,0 1,0 0,0 0,0 1,1 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,-1z" />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="32,42 32,44 32,44 32,42 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 43c0,0 1,0 1,-1 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,1 -2,1 0,0 0,0 0,0 0,0 0,1 0,1 0,0 0,0 1,-1z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M36 42c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 -1,0 -1,1 0,0 -1,0 -1,1 0,0 0,0 0,0l2 -1c0,0 0,-1 0,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M35 42c0,0 0,0 0,0l-2 1c0,0 0,0 0,0 1,0 1,0 2,-1 0,0 0,0 0,0z" />
            <path fill="#E4E8F0" fill-rule="nonzero" d="M33 43c0,0 0,0 0,0l2 -1 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,1 -2,1l0 0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M35 41l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#D3D9E2" fill-rule="nonzero" d="M35 42l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M32 43l0 0c0,0 1,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,-1 0,0 0,0 0,0 0,0 -1,-1 -1,-1z" />
            <path fill="#474A60" fill-rule="nonzero" d="M36 44l-2 1c0,0 0,0 0,0 0,0 0,0 0,0l2 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M36 44l-2 1c0,0 0,0 0,0 0,0 0,0 0,0l2 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#F89F32" fill-rule="nonzero" points="31,42 34,40 35,40 32,42 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="31,41 34,40 35,40 32,42 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="32,42 32,42 35,40 35,40 " />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M33 40c0,0 0,0 0,0 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,-1 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 4,2 4,2l0 0c0,0 2,-1 2,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M33 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 4,2 4,2l0 0c0,0 2,-1 2,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M29 39c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="29,39 29,39 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M28 39l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 40l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M32 40l0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M29 39c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 39c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M32 40c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 39c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 39l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 39l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 40l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 41l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M29 39l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 40l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 41l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 40l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M29 39c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 39c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M31 40c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <line fill="#C9D1E2" fill-rule="nonzero" x1="28" y1="39" x2="28" y2="39" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M28 39l0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M28 40l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="28,39 28,39 28,39 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M30 40l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="30,40 30,40 30,40 " />
          </g>
          <g>
            <path fill="#C3C9D6" fill-rule="nonzero" d="M41 36c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M49 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 0,0 0,-1 0,0 -1,-1 -1,-1 0,0 -1,-1 -1,-1l0 0 0 0c0,0 0,0 0,0 0,1 0,1 0,2 0,0 2,1 2,1 0,0 0,0 0,0l0 1 1 0c0,0 0,0 0,0l0 0 0 0c0,0 0,-1 0,-1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M46 31c0,0 1,1 1,1 0,0 0,0 0,1 0,0 0,0 1,0 0,0 0,0 0,0 -1,-1 -2,-2 -2,-2 0,-1 -1,-1 -1,-1 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 1,0 1,1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M45 31c0,0 0,0 -1,1 0,1 0,1 0,2 1,0 3,1 3,2 1,0 1,0 0,-1 0,-1 -2,-2 -2,-2 0,-1 -1,-1 -1,-1z" />
            <path fill="#F89F32" fill-rule="nonzero" d="M45 33c-1,0 -1,-1 0,-2 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 -1,1 0,1 0,1 0,2 1,0 2,1 3,2 0,0 0,0 1,0 0,0 0,0 0,0 -1,0 -2,-1 -3,-2z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 34c0,0 0,0 0,0 0,0 -2,-1 -2,-1 0,0 -1,-1 0,-1 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,1 0,1 0,1 0,2 1,0 2,1 3,2 0,0 0,0 0,0 0,0 0,-1 0,-1z" />
            <path fill="#F89F32" fill-rule="nonzero" d="M45 31c0,0 0,1 0,1 0,0 2,1 2,1 0,0 0,0 0,0l0 0c0,-1 -2,-2 -2,-2 0,0 -1,-1 -1,-1 0,0 0,0 0,1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M44 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M48 34c0,0 0,-1 0,-1l-3 -2c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,1 0,1l3 2 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M44 33l3 2c0,0 0,0 0,0 0,0 0,-1 0,-1l-3 -2c0,0 0,0 0,0 0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M46 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M45 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="45,33 45,33 45,33 45,33 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="45,33 45,33 45,33 45,33 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="46,34 46,34 46,33 46,34 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="46,34 46,34 46,33 46,34 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="47,35 46,34 46,34 47,34 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="47,34 47,34 47,34 47,34 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M47 35l0 0 -3 -2c0,0 0,0 0,0 0,0 0,0 0,0l3 2c0,0 0,0 0,0l0 0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M45 33c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M44 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M44 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M44 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M47 34c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M47 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M47 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M47 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M48 34c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M47 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M47 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M47 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M49 34l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M49 34l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 30l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 30l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 31l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M47 31l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M47 31l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M47 32l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 32l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 32l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 33l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 33l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M49 33l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M46 32c0,0 0,-1 0,-1 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,1l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M45 31c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M45 31c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 31c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M46 32c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M46 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M46 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 -2,-1 -2,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 2,1 2,1 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 34c0,0 -2,-2 -2,-3 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 2,2 2,3 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1z" />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="46,34 44,32 45,32 47,33 " />
            <polygon fill="#FBAF36" fill-rule="nonzero" points="45,32 43,31 44,31 46,32 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="46,36 46,34 44,32 44,32 43,31 43,34 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="47,35 47,33 46,34 46,36 " />
            <path fill="#373A4A" fill-rule="nonzero" d="M46 31c0,0 0,-1 -1,-1 0,0 -1,0 -1,0 0,0 0,0 0,0l-1 1 2 3 1 -1c0,0 0,0 0,0 0,0 0,0 0,-1z" />
            <path fill="#30323C" fill-rule="nonzero" d="M44 31c0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,-1 -1,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M44 31c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M44 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M44 31c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 -1,-1z" />
            <path fill="#E4E8F0" fill-rule="nonzero" d="M44 31l0 0c0,0 0,0 0,-1 0,0 0,0 1,1 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M44 31c0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0l0 1c0,0 0,0 0,0z" />
            <path fill="#D3D9E2" fill-rule="nonzero" d="M44 31l0 -1c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M46 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,0 1,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M46 31c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 0,0 0,0l1 -1c0,0 0,0 0,-1 0,0 0,0 0,-1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M46 31c0,0 0,0 0,0l-1 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#E4E8F0" fill-rule="nonzero" d="M45 31c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#A9B4C5" fill-rule="nonzero" d="M46 31c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#D3D9E2" fill-rule="nonzero" d="M46 31l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M46 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 0,0 0,0 0,-1 0,0 -1,-1 -1,-1 0,0 -1,-1 -1,-1l0 0 0 0c0,0 0,0 0,0 0,1 0,1 0,2 0,0 2,1 2,1 0,0 0,0 0,0l0 1 1 0c0,0 0,0 0,0l0 0 0 0c0,0 0,-1 0,-1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M44 33c0,0 1,1 1,1 0,0 0,0 0,1 0,0 0,0 1,0 0,0 0,0 0,0 -1,-1 -2,-2 -2,-2 0,-1 -1,-1 -1,-1 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 1,0 1,1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M42 32c0,0 0,0 -1,1 0,1 0,1 0,2 1,0 3,1 3,2 1,0 1,0 0,-1 0,-1 -2,-2 -2,-2 0,-1 -1,-1 -1,-1z" />
            <path fill="#F89F32" fill-rule="nonzero" d="M42 34c-1,0 -1,-1 0,-2 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 -1,1 0,1 0,1 0,2 1,0 2,1 3,2 0,0 0,0 1,0 0,0 0,0 0,0 -1,0 -2,-1 -3,-2z" />
            <path fill="#F58934" fill-rule="nonzero" d="M45 35c0,0 0,0 0,0 0,0 -2,-1 -2,-1 0,0 -1,-1 0,-1 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,1 0,1 0,1 0,2 1,0 2,1 3,2 0,0 0,0 0,0 0,0 0,-1 0,-1z" />
            <path fill="#F89F32" fill-rule="nonzero" d="M42 33c0,0 0,1 0,1 0,0 2,1 2,1 0,0 0,0 0,0l0 0c0,-1 -2,-2 -2,-2 0,0 -1,-1 -1,-1 0,0 0,0 0,1z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M45 36c0,0 0,-1 0,-1l-3 -2c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,1 0,1l3 2 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 35l3 2c0,0 0,0 0,0 0,0 0,-1 0,-1l-3 -2c0,0 0,0 0,0l0 0c0,0 0,1 0,1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M44 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M43 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M42 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="43,35 42,35 42,34 43,35 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="43,35 42,35 42,34 43,35 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="44,35 43,35 43,35 44,35 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="43,35 43,35 43,35 43,35 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="44,36 44,36 44,35 44,36 " />
            <polygon fill="#A9B4C5" fill-rule="nonzero" points="44,36 44,36 44,35 44,36 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M45 36l0 0 -3 -2c0,0 0,0 0,0 0,0 0,0 0,0l3 2c0,0 0,0 0,0l0 0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M42 34c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M42 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M42 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M44 35c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M44 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M44 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M44 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M45 36c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M45 36c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M45 36c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M45 36c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 35l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 36l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M43 32l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M43 32l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M44 32l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M44 33l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M44 33l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M45 33l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M45 34l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M45 34l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 34l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 35l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M46 35l-1 0c0,0 0,0 0,0 0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M43 33c0,0 0,-1 0,-1 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,1l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M42 33c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M42 33c0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M43 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M42 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M44 34c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M43 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#3E3F53" fill-rule="nonzero" d="M43 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M45 36c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 -2,-1 -2,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 2,1 2,1 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M45 35c0,0 -2,-2 -2,-3 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 2,2 2,3 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M47 35l-1 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0l1 0c0,0 0,0 0,0l0 1c0,0 0,0 0,0z" />
            <polygon fill="#474A60" fill-rule="nonzero" points="47,33 46,34 46,34 47,33 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="47,34 46,34 46,34 47,34 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="47,34 46,35 46,34 47,34 " />
            <polygon fill="#474A60" fill-rule="nonzero" points="47,34 46,35 46,35 47,34 " />
            <path fill="#474A60" fill-rule="nonzero" d="M47 35l-1 1c0,0 0,0 0,0 0,0 0,0 0,0l1 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M47 32l0 1c0,0 0,0 0,0 0,0 0,0 0,0l0 -1 0 0z" />
            <path fill="#474A60" fill-rule="nonzero" d="M47 32c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#FFCA31" fill-rule="nonzero" points="46,33 45,32 46,32 47,33 " />
            <path fill="#F89F32" fill-rule="nonzero" d="M45 32c0,0 0,0 0,0 0,0 1,1 1,1l0 0 -1 0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="47,33 47,33 46,33 46,33 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="47,33 47,33 46,33 47,33 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="47,33 46,33 46,33 47,33 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="47,33 46,33 46,33 46,33 " />
            <polygon fill="#F89F32" fill-rule="nonzero" points="46,33 46,33 46,33 46,33 " />
            <path fill="#474A60" fill-rule="nonzero" d="M45 30c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l1 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M44 30l1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M49 34c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,1 1,1 0,0 0,0 0,-1 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F89F32" fill-rule="nonzero" d="M48 35c0,0 0,0 0,0 0,0 0,1 0,1 0,0 1,0 1,1l0 0 -1 -1 -1 -1 0 -1 -2 1 0 1c0,0 0,0 0,0 0,0 1,0 1,0z" />
            <path fill="#FBAF36" fill-rule="nonzero" d="M47 37l1 1 2 -1c-1,0 -1,-1 -1,-1 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 0,0 0,0l0 0 1 1z" />
            <path fill="#F58934" fill-rule="nonzero" d="M48 37c0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,0 1,1 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,-1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M46 35c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,1 1,1 0,0 0,0 0,-1 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#F58934" fill-rule="nonzero" d="M50 36c0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,0 1,1 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,-1z" />
            <path fill="#FFCA31" fill-rule="nonzero" d="M48 34c0,0 0,1 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 1,1 1,1 0,0 0,0 0,-1 0,0 0,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <polygon fill="#F58934" fill-rule="nonzero" points="49,33 46,35 46,35 48,33 " />
            <polygon fill="#F58934" fill-rule="nonzero" points="48,38 50,36 50,36 48,38 " />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M51 37c0,0 0,0 0,0 0,0 -1,0 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 1,0 2,0 0,0 0,0 0,-1 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M50 37c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 1,0 2,0 0,0 0,0 0,-1 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C3C9D6" fill-rule="nonzero" d="M50 38c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C4B396" fill-rule="nonzero" d="M47 36c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0l0 0 0 0z" />
            <path fill="#B3BDCE" fill-rule="nonzero" d="M49 38c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 -1,0 -1,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 36l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 36c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 35c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 35c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 34l0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 35l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 36l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0z" />
            <line fill="#C9D1E2" fill-rule="nonzero" x1="48" y1="36" x2="48" y2="36" />
            <line fill="#C9D1E2" fill-rule="nonzero" x1="48" y1="36" x2="48" y2="36" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 36l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <polygon fill="#C9D1E2" fill-rule="nonzero" points="48,36 48,36 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M48 37l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 36l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 36l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 36c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M50 36c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 37l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M49 37c0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M50 37l0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0z" />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M50 37c0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0l0 0z" />
          </g>
          <g>
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 34c0,0 0,0 0,0 0,0 -1,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,0 1,0 0,0 1,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C3C9D6" fill-rule="nonzero" d="M36 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm1 -1c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#B3BDCE" fill-rule="nonzero" d="M36 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M35 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 34l0 0 0 0 0 0 0 0zm0 0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="36,34 36,34 36,34 36,34 36,34 36,34 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="36,34 36,34 36,34 36,34 36,34 36,34 36,34 36,34 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M37 34c0,0 0,0 0,0 0,0 -1,-1 -1,-1 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,0 1,0 0,0 1,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#B3BDCE" fill-rule="nonzero" d="M36 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C3C9D6" fill-rule="nonzero" d="M37 34c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#B3BDCE" fill-rule="nonzero" d="M36 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M35 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M36 33l0 0 0 0 0 0 0 0zm0 0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="36,33 36,33 36,33 36,33 36,33 36,33 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="36,33 36,33 36,33 36,33 36,33 36,33 36,33 36,33 " />
            <path fill="#C9D1E2" fill-rule="nonzero" d="M36 33c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,0 0,0 0,0z" />
            <path fill="#C3C9D6" fill-rule="nonzero" d="M35 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0zm-1 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0l0 0c0,0 0,0 0,0 0,0 0,0 0,0l0 0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#C3C9D6" fill-rule="nonzero" d="M35 33l0 0c0,0 0,0 0,0 0,0 0,0 0,0z" />
            <path fill="#BAC5D2" fill-rule="nonzero" d="M36 33c0,0 0,0 0,0 0,0 0,0 0,0l0 0z" />
            <path fill="#B3BDCE" fill-rule="nonzero" d="M35 35c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#E1E9F3" fill-rule="nonzero" d="M35 34c0,0 0,-1 0,-1 0,0 -1,0 -1,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 1,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M35 34c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0 0 0 0 0 0 0c0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0l0 0c0,0 0,0 0,0z" />
            <path fill="#373A4A" fill-rule="nonzero" d="M35 34l0 0 0 0 0 0 0 0zm0 0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0z" />
            <polygon fill="#373A4A" fill-rule="nonzero" points="35,34 35,34 35,34 35,34 35,34 35,34 " />
            <polygon fill="#373A4A" fill-rule="nonzero" points="35,34 35,34 35,34 35,34 35,34 35,34 35,34 35,34 " />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;