import React from "react";
import styled from "styled-components";
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import OceanFreightIcon from "../../assets/svg/Services/OceanFreight";
import MultimodalFreightIcon from "../../assets/svg/Services/MultimodalFreight";
import AirFreightIcon from "../../assets/svg/Services/AirFreight";
import RoadFreightIcon from "../../assets/svg/Services/RoadFreight";
import SaltProductionsIcon from "../../assets/svg/Services/SaltProductions";
import commstyles from "../../style/module/common.module.css";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "MultimodalFreight":
      getIcon = <MultimodalFreightIcon />;
      break;
    case "OceanFreight":
      getIcon = <OceanFreightIcon />;
      break;
    case "RoadFreight":
      getIcon = <RoadFreightIcon />;
      break;
    case "AirFreight":
      getIcon = <AirFreightIcon />;
      break;
    case "SaltProduction":
      getIcon = <SaltProductionsIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle className={`${commstyles['serv-ico-box']}`}>{getIcon}</IconStyle>
      <TitleStyle className={`${commstyles['serv-title']} font20 extraBold`}>{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 30px 0;
  @media (max-width: 860px) {
    padding: 10px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;