import React from "react";
import styled from "styled-components";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commstyles from "../../style/module/common.module.css";

export default function Contact() {

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact Us</h1>
            <p className="font15">
              Thank you for your interest in PT. Pacific Multi Jaya. Please feel free to reach out to us through any of the following methods:
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h3>Map & Directions</h3>
              <iframe title="gmaps" width="100%" height="350" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=350&amp;hl=en&amp;q=Jl.%20Pantai%20Indah%20Selatan%20I,%20No.4,%20RT.4/RW.3,%20Kamal%20Muara,%20Kec.%20Penjaringan,%20Jkt%20Utara,%20Daerah%20Khusus%20Ibukota%20Jakarta%2014460+(PT.%20Pacific%20Multi%20Jaya)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              </iframe>
            </div>

            <div className={`${commstyles['no-wrap']} col-xs-12 col-sm-12 col-md-6 col-lg-6`}>
              <h3>Contact Informations</h3>
              <div className={`${commstyles['row-contact']} ${commstyles['no-wrap']}`}>
                <div className={`${commstyles['footer-icon-container']}`}><FontAwesomeIcon icon={faEnvelope} size={'sm'} /></div>
                <div className={`${commstyles['footer-cu-spacer']}`} />
                <div>info@pmj-id.com</div>
              </div>
              <br/>
              <h3>Address</h3>
              <div>Jl. Pantai Indah Selatan I, No.4, RT.4/RW.3, Kamal Muara, Kec. Penjaringan, Jakarta Utara, Daerah Khusus Ibukota Jakarta 14460</div>
              <br/>
              <h3>Office Hours</h3>
              <div>Monday - Friday : 09:00 AM - 04:00 PM</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;