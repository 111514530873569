import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0.987354in" height="0.666665in" viewBox="0 0 56.03 37.83" {...props} >
      <g id="Layer_x0020_1">
        <g id="_2815427388992">
          <g>
            <path fill="#61AFF6" d="M21.56 23.66l-0 0.03c0.17,0.07 2.28,0.02 2.64,0.02 4.4,0 8.83,-0.02 13.21,-0 0.21,0 0.42,0.03 0.64,0.03 1.49,-0 3.19,0.03 4.62,-0.01l0 2.52 2.34 -0.01 0.05 -0.03c0.01,-0.2 -0.04,-0.4 -0.01,-0.64 0.02,-0.19 0.01,-0.43 0.06,-0.61l0.15 -0.52c0.06,-0.21 0.31,-0.68 0.44,-0.87 0.11,-0.17 0.47,-0.59 0.62,-0.71 0.13,-0.11 0.22,-0.2 0.35,-0.3 0.11,-0.09 0.28,-0.19 0.41,-0.25 0.56,-0.28 0.29,-0.19 0.95,-0.38 0.1,-0.03 0.15,-0.06 0.27,-0.07 0.25,-0.02 0.75,-0.09 0.89,-0.07 0.21,0.03 0.38,0.04 0.58,0.06l0.77 0.23c0.09,0.03 0.14,0.07 0.23,0.09l0.13 0.04 -0 -4.48c-0.69,-0.02 -1.44,-0 -2.13,-0 -0.7,0 -1.41,0 -2.11,0 -0.33,0 -0.71,0.04 -1.01,-0.05 -0.33,-0.1 -0.4,-0.37 -0.4,-0.67l0 -5.29c-0,-0.41 0.01,-0.6 0.29,-0.77 0.24,-0.15 1.63,-0.08 2.03,-0.08 0.35,0 0.7,0 1.06,-0 0.27,-0 0.8,-0.03 1.04,0.01 -0.26,-0.02 -0.57,-0.01 -0.83,-0.01 -0.35,0 -0.31,0.05 -0.6,-0.28 -0.27,-0.29 -0.56,-0.57 -0.86,-0.87 -0.35,-0.35 -1.25,-1.4 -1.71,-1.52 -0.1,-0.02 -0.12,0 -0.12,-0.1 -0.46,0 -0.93,0 -1.39,0 -0.29,0 -1.21,0.03 -1.4,-0.01 -0.04,0.08 -0.02,12.68 -0.03,15.23 -0,0.21 0.03,0.28 -0.07,0.41 -0.29,0.03 -3.95,0.01 -4.64,0.01 -0.22,0 -0.43,-0.03 -0.62,-0.03 -1.76,0.02 -3.53,0 -5.29,0l-7.93 0c-0.59,0 -2.14,0.03 -2.62,-0.01z" />
            <path fill="#3999EA" d="M11.07 26.24l43.81 0c0.64,0 1.16,-0.52 1.16,-1.16l0 -11.47c0,-0.33 -0.19,-0.6 -0.54,-0.96 -1.05,-1.07 -1.17,-1.22 -1.96,-2.01 -0.53,-0.53 -1.24,-1.25 -1.72,-1.74 -0.46,-0.46 -0.6,-0.84 -2.02,-0.84l-7.04 0 0 15.64 -5.01 0 0 0.02 -27.67 0 0 1.52c0,0.55 0.45,1 1,1z" />
            <path fill="#61AFF6" d="M42.74 26.24l7.01 0c0.64,0 1.16,-0.52 1.16,-1.16l0 -11.47c0,-0.33 -0.19,-0.6 -0.54,-0.96 -1.05,-1.07 -1.17,-1.22 -1.96,-2.01 -0.53,-0.53 -1.24,-1.25 -1.72,-1.74 -0.46,-0.46 -0.6,-0.84 -2.02,-0.84l-1.92 0 0 18.18z" />
            <g>
              <circle fill="#3D6586" cx="49.05" cy="25.79" r="4.03" />
              <circle fill="#CDCBC9" cx="49.05" cy="25.79" r="1.57" />
            </g>
            <path fill="#32D8DD" d="M50.9 17.7c0.55,-0.02 1.13,-0 1.68,0 0.28,0 0.49,0 0.67,-0.16 0.14,-0.12 0.24,-0.32 0.24,-0.58 -0,-0.55 -0,-1.1 -0,-1.66 0,-0.78 -0.01,-0.81 -0.42,-1.23 -0.52,-0.53 -1.13,-1.13 -1.65,-1.66 -0.28,-0.28 -0.54,-0.55 -0.82,-0.83 -0.3,-0.3 -0.59,-0.69 -0.93,-0.74 -0.24,-0.04 -0.78,-0.01 -1.04,-0.01 -0.35,0 -0.7,0 -1.06,0 -0.4,0 -1.79,-0.06 -2.03,0.08 -0.28,0.17 -0.29,0.36 -0.29,0.77l-0 5.29c0,0.3 0.07,0.56 0.4,0.67 0.29,0.09 0.67,0.05 1.01,0.05 0.7,0 1.41,0 2.11,0 0.7,0 1.44,-0.02 2.13,0z" />
            <path fill="#EDA458" d="M37.55 23.7l5.2 0 0 -22.79c0,-0.5 -0.41,-0.92 -0.92,-0.92l-4.29 0 0 23.7z" />
            <path fill="#FFC06C" d="M37.74 23.72l-27.67 0 0 -22.81c0,-0.5 0.41,-0.92 0.92,-0.92l26.75 0 0 23.72z" />
            <path fill="#FF4C4C" d="M30.02 0l-7.65 0 -0 7.65c-0,0.58 0.13,0.81 0.73,0.8l6.14 -0c0.31,0 0.41,0.03 0.61,-0.15 0.18,-0.16 0.18,-0.31 0.18,-0.61 -0,-0.51 -0,-1.03 -0,-1.54 0,-2.05 0.02,-4.11 -0,-6.16z" />
            <rect fill="#FEFEFE" x="31.11" y="15.24" width="8.61" height="1.38" rx="3.98" ry="0.6" />
            <rect fill="#FEFEFE" x="31.11" y="19.23" width="8.61" height="1.38" rx="3.98" ry="0.6" />
            <g>
              <circle fill="#3D6586" cx="18.12" cy="25.79" r="4.03" />
              <circle fill="#CDCBC9" cx="18.12" cy="25.79" r="1.57" />
            </g>
          </g>
          <g>
            <path fill="#BE7D5A" d="M8.79 37.17c-0.24,-0.36 -0.15,-0.49 -0.15,-1.41 -0,-1.12 -0.21,-3.44 0.15,-4 -0.65,-0.1 -3.76,-2.03 -4.5,-2.46 -0.76,-0.44 -1.41,-0.83 -2.16,-1.25 -0.69,-0.39 -1.41,-0.89 -2.13,-1.22l0 5.42 8.19 4.74c0.22,0.14 0.3,0.24 0.6,0.18z" />
            <path fill="#FDBB1E" d="M27.43 31.69l-0.78 -0.31 -0.79 1.16 0.01 -1.89 -2 1.09c-0.16,0.53 -0.07,5.16 -0.03,6.09 0.28,-0.09 0.38,-0.2 0.63,-0.34 0.78,-0.44 4.71,-2.77 5.1,-2.86l0 -4.65c0,-0.35 0.07,-1.18 -0.1,-1.44l-1.93 1.1c0.03,0.44 0.08,1.45 -0.03,1.88l-0.08 0.17z" />
            <path fill="#F9A83C" d="M23.84 37.83c-0.04,-0.93 -0.12,-5.56 0.03,-6.09 -0.47,-0.07 -4.38,-2.42 -5.05,-2.84l0 6.14c0.4,0.09 2,1.09 2.46,1.36l2.56 1.43z" />
            <path fill="#AA6256" d="M9.03 36.48l0 -1.23 2.08 -1.23c0.03,1.59 0.17,1.2 -0.96,1.87 -0.3,0.18 -0.81,0.52 -1.12,0.6zm2.83 -6.58l-0.02 1.77 -0.69 -0.24 -0.69 1.02 -0.01 -1.63 -1.67 0.94c-0.35,0.55 -0.15,2.88 -0.15,4 0,0.92 -0.09,1.05 0.15,1.41l4.96 -2.85c0,-0.99 0.17,-4.89 -0.06,-5.48l-1.82 1.07z" />
            <path fill="#DAA560" d="M-0 26.83c0.72,0.33 1.44,0.83 2.13,1.22 0.75,0.42 1.4,0.82 2.16,1.25 0.74,0.43 3.86,2.36 4.5,2.46l1.67 -0.94c-0.37,-0.39 -3.58,-2.15 -4.3,-2.56 -0.76,-0.44 -1.4,-0.83 -2.16,-1.25 -0.45,-0.26 -1.82,-0.98 -2.1,-1.27 -0.35,0.08 -0.69,0.31 -0.99,0.5 -0.36,0.22 -0.65,0.31 -0.91,0.6z" />
            <path fill="#DAA560" d="M3.31 24.91l8.55 5 1.82 -1.07 -2.11 -1.23c-0.43,-0.26 -6.29,-3.73 -6.51,-3.73l-1.7 0.99c-0.01,0.01 -0.04,0.03 -0.05,0.05z" />
            <path fill="#EEEDFC" d="M1.9 25.74c0.28,0.29 1.65,1.02 2.1,1.27 0.76,0.43 1.4,0.82 2.16,1.25 0.72,0.41 3.93,2.17 4.3,2.56l0.01 1.63 0.69 -1.02 0.69 0.24 0.02 -1.77 -8.55 -5c-0.36,0.08 -0.46,0.21 -0.76,0.38 -0.28,0.15 -0.43,0.2 -0.65,0.45z" />
            <path fill="#FFD06C" d="M22.33 26.84c0.45,0.06 2.13,1.1 2.58,1.37 0.65,0.39 1.86,1.17 2.49,1.42l0.02 2.05 0.08 -0.17c0.11,-0.43 0.06,-1.44 0.03,-1.88l1.93 -1.1c-0.42,-0.38 -1.23,-0.77 -1.73,-1.07 -0.32,-0.19 -0.55,-0.31 -0.85,-0.49l-1.76 -1.04c-0.75,-0.47 -0.67,-0.3 -1.9,0.39 -0.29,0.16 -0.65,0.36 -0.9,0.52z" />
            <path fill="#FFD06C" d="M18.82 28.91c0.68,0.42 4.58,2.77 5.05,2.84l2 -1.09 -0.3 -0.2c-0.86,-0.52 -4.41,-2.48 -4.63,-2.75 -0.44,0.17 -0.73,0.37 -1.12,0.6 -0.36,0.22 -0.68,0.4 -1.01,0.6z" />
            <path fill="#EDECFA" d="M20.95 27.7c0.21,0.27 3.76,2.23 4.63,2.75l0.3 0.2 -0.01 1.89 0.79 -1.16 0.78 0.31 -0.02 -2.05c-0.63,-0.25 -1.84,-1.03 -2.49,-1.42 -0.46,-0.28 -2.13,-1.31 -2.58,-1.37 -0.4,0.28 -1.1,0.54 -1.38,0.86z" />
            <path fill="#7A8497" d="M9.03 36.48c0.31,-0.08 0.82,-0.42 1.12,-0.6 1.13,-0.66 0.99,-0.28 0.96,-1.87l-2.08 1.23 -0 1.23z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;