import React from "react";
import styled from "styled-components";
import TestimonialSlider from "../Elements/TestimonialSlider";
import commstyles from "../../style/module/common.module.css";
import clientLogo01 from "../../assets/img/clients/sca.png";
import clientLogo02 from "../../assets/img/clients/jdm.png";

export default function Blog() {
  return (
    <Wrapper id="about">
      <div className={`${commstyles['about']} whiteBg`}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
            <h3>PT. Pacific Multi Jaya</h3>
            <p className="font15">
              Welcome to PT. Pacific Multi Jaya, a leading conglomerate dedicated to delivering innovative solutions across diverse industries in Indonesia. 
              Our company stands as a pillar of excellence in logistics and cloud seeding material sales, reflecting our commitment to enhancing operational 
              efficiency and environmental sustainability.
              <br />
              <br />
              <b>Our Vision</b>
              <br />
              To be the leading provider of logistics and supply cloud seeding materials in Indonesia, known for our innovation, reliability, and commitment 
              to improving the quality of business through our services.
              <br />
              <br />
              <b>Our Mission</b>
              <br />
              Our mission is to drive progress through our expertise in logistics and advanced technology. We are committed to providing high-quality products 
              and services that meet the evolving needs of our clients while contributing positively to the communities we serve.
            </p>
          </HeaderInfo>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "20px 0" }}>
        <div className={`${commstyles['row-client']} ${commstyles['flex-c']}`}>
          <h4>Our Clients</h4>
        </div>
        <div className="container">
          <div className={`${commstyles['row-client']} ${commstyles['flex-c']}`}>
            <div className={`${commstyles['img-client-container']} `}>
              <img src={clientLogo01} title="smartaviation" alt='c1'/>
            </div>
            <div className={`${commstyles['img-client-container']} `}>
              <img src={clientLogo02} title="PT. Jasa Dinamika Mandiri" alt='c2'/>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font15">
              We are honored to have the trust and appreciation of our clients. Here’s what some of them have to say about their experience with our logistics services:
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;